import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import FavoriteIcon from '@material-ui/icons/Favorite';
import logo1 from '../assets/images/logo1.png';

import logo from '../assets/images/logo.png';
import ban1 from '../assets/images/banner-01.jpg';
import ban2 from '../assets/images/banner-02.jpg';
import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
import add1 from '../assets/images/add-img-01.jpg';
import add2 from '../assets/images/add-img-02.jpg';
import par1 from '../assets/images/img-pro-01.jpg';
import par2 from '../assets/images/img-pro-02.jpg';
import par3 from '../assets/images/img-pro-03.jpg';
import par4 from '../assets/images/img-pro-04.jpg';



import pro1 from '../assets/images/img-pro-01.jpg';
import pro2 from '../assets/images/img-pro-02.jpg';
import pro3 from '../assets/images/img-pro-03.jpg';

import in1 from '../assets/images/instagram-img-01.jpg';
import in2 from '../assets/images/instagram-img-02.jpg';
import in3 from '../assets/images/instagram-img-03.jpg';
import in4 from '../assets/images/instagram-img-04.jpg';


// import pro4 from '../assets/images/img-pro-01.jpg';
// import pro5 from '../assets/images/img-pro-01.jpg';
// import pro6 from '../assets/images/img-pro-01.jpg';
// import pro7 from '../assets/images/img-pro-01.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ReplayIcon from '@material-ui/icons/Replay';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PaymentIcon from '@material-ui/icons/Payment';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import PermIdentitySharpIcon from '@material-ui/icons/PermIdentitySharp';
import RemoveRedEyeSharpIcon from '@material-ui/icons/RemoveRedEyeSharp';

import SearchIcon from '@material-ui/icons/Search';





import car1 from './img/carousel-1.jpg';
import car2 from './img/carousel-2.jpg';



import cat1 from './img/cat-1.jpg';
import cat2 from './img/cat-2.jpg';
import cat3 from './img/cat-3.jpg';
import cat4 from './img/cat-4.jpg';
import cat5 from './img/cat-5.jpg';
import cat6 from './img/cat-6.jpg';







import product1 from './img/product-1.jpg';
import product2 from './img/product-2.jpg';
import product3 from './img/product-3.jpg';
import product4 from './img/product-4.jpg';
import product5 from './img/product-5.jpg';
import product6 from './img/product-6.jpg';
import product7 from './img/product-7.jpg';
import product8 from './img/product-8.jpg';

import vendor1 from './img/vendor-1.jpg';
import vendor2 from './img/vendor-2.jpg';
import vendor3 from './img/vendor-3.jpg';
import vendor4 from './img/vendor-4.jpg';
import vendor5 from './img/vendor-5.jpg';
import vendor6 from './img/vendor-6.jpg';
import vendor7 from './img/vendor-7.jpg';
import vendor8 from './img/vendor-8.jpg';





















const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")

        fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })


      fetch("https://halal-dropship-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://halal-dropship-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setSliderPhoto(res1)
        
        })


        
                    fetch("https://halal-dropship-main-back.vercel.app/TagsSort1111",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res9=>res9.json())
                .then(res8=>{
                    const dta = res8.filter((res7,i)=>{
                        if(res7.MainCategories ===Categoriesname.categoriesName  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                            console.log(res7);
                            return res7
                        }
                    })
                    // console.log(dta);
                    setSortedData(dta)
                })
                fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                console.log(res3);
                // setMainCatogories(res3)
                
    
                let dat = []
                res3.map((res55,c)=>{
                    dat.push(res55)
                })
                // console.log(SubClassCategories,asse)
                console.log(dat)
                let data = dat.sort((a, b) => {
                    return a.sort - b.sort;
                });
                setMainCatogories(data)
                
              })
                    fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res4=>res4.json())
                .then(res5=>{
                    let data = res5.filter((res6,i)=>{
                        if (res6.MainCategories === Categoriesname.categoriesName) return res6
                    })
                    setsubCate(data)
                    // console.log(res5,data);
                })

                fetch("https://halal-dropship-main-back.vercel.app/CourseAllSubCategories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                  let data = res3.sort((a, b) => {
                      return a.sort - b.sort;
                  });
                  // console.log(data);
                  setSubClassCategories(data)
              })

                fetch("https://halal-dropship-main-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === Categoriesname.categoriesName &&  res9.Product_Sub_Catagories === Categoriesname.categoriesName1 &&  res9.Course_Sub_Catagories === Categoriesname.categoriesName2 ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(NonActive)
                })

        

      






        // fetch("https://halal-dropship-main-back.vercel.app/CategoriesShop",{
        //                         method: "GET",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         }
        //                     })
        //                     .then(res=>res.json())
        //                     .then(res1=>{
        //                         const Pro = res1.filter((res2,i)=>{
        //                             // console.log(res2.Product_Catagories=== Category1,res2.Product_Catagories , " yyy  ", Category1);
        //                             return res2.Product_Catagories === data1
        //                         })
        //                         setProduct(Pro)
        //                         console.log(Pro);
        //                     })
        // // console.log(Catego/ry1);
        //     fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
        //         method: "GET",
        //             headers :  {
        //             "Content-Type" : "application/json" , 
        //         } ,
        //     })
        //     .then(res4=>res4.json())
        //     .then(res5=>{
        //         setsubCate(res5)
        //         console.log(res5);
        //     })

        // }
        // else{
        //     var data1 = JSON.parse(localStorage.getItem("Cate")) 

        //     setCategoriesUrl(data1)
            
        //     const Pro = JSON.parse(localStorage.getItem("CateProduct"))
        //                             setProduct(Pro)
        //                             console.log(Pro);
        //         fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
        //             method: "GET",
        //                 headers :  {
        //                 "Content-Type" : "application/json" , 
        //             } ,
        //         })
        //         .then(res4=>res4.json())
        //         .then(res5=>{
        //             setCategories(res5)
        //             // console.log(res1);
        //         })
        // }
       
        // },1000)
       
    // return () => clearInterval(Inter);

},[])


const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
// useEffect(() => {
  
//     return () => {
//         clearInterval()
//     }
//   }, [])



const SortData1 = (a ,b) =>{
            setFal1(false)

          const Pro6 = Product.sort((a, b)=> {
                return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
            });
            setProduct1(Pro6)
            setFal(true)
            console.log(Product1);



            
}
const SortData2 = (a ,b) =>{
    setFal(false)
          const Pro6 = Product.sort((a, b)=> {
            var nameA = a.Product_Name.toUpperCase(); 
            var nameB = b.Product_Name.toUpperCase(); 
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
            });
            setProduct2(Pro6)
            setFal1(true)
            console.log(Product2);



            
}


const ViewAtDetails = ()=>{
    // console.log("fddddddddddddddddd")
   let data1 =  document.getElementById("view-at-hover")
   data1.setAttribute("class" , "hover-at-view")
}

const NoViewAtDetails = ()=>{
    // console.log("fddddddddddddddddd")
   let data1 =  document.getElementById("view-at-hover")
   data1.removeAttribute("class")
}

// const setSortPrice = (e)=>{
//     SortedName.push(e)
//     console.log(e);
//     if (e === "Low"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//                 return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
//             });
//             setSubproduct(Pro6)

//     }
//     if (e === "A"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//             var nameA = a.Product_Name.toUpperCase(); 
//             var nameB = b.Product_Name.toUpperCase(); 
//             if (nameA < nameB) {
//                 return -1;
//             }
//             if (nameA > nameB) {
//                 return 1;
//             }

//             return 0;
//             });
//             console.log(Pro6);
//             setSubproduct(Pro6)

//     }
//     if (fal)
//     setFal(true)
//     else
//     setFal(false)
// }




const sortedAlgo = (e)=>{
    let d = false
    let da = 0
    SortedName.map((res9,a)=>{
        if(res9 === e){
            d = true
            da = a
        }
    })
    if(d){
        SortedName.splice(da,1)
    }
    else{
        SortedName.push(e)
    }
    fetch("https://halal-dropship-main-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , CategoriesName.categoriesName  ,res9.Product_Sub_Catagories , Categoriesname.subcategories);
                        if(res9.status && res9.Product_Catagories === CategoriesName.categoriesName){
                            return res9 
                        }
                    })
                
    if(SortedName.length > 0){
        let Coa = []
        SortedName.map((res22,x)=>{
      console.log(res22,SortedName)
//    console.log(Subproduct)
   const Sort = NonActive.filter((res,i)=>{
        res.Product_Name.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        res.Product_Title.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        
            res.tags.map((res1,i)=>{
                if (res1 === res22){
                    // console.log("true")
                    Coa.push(res)
                }
            })
        // }
   })  
    })
     let uniq = [...new Set(Coa)];

   console.log(uniq);
   setSubproduct(uniq)
}
else{
        setSubproduct(NonActive)
    }
   
  })
}














// const addtocartproduct = (...data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


       




  
//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 if (data111[0].Product_Price_Discounted === null){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else if (data111[0].Product_Price_Discounted){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price_Discounted *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price_Discounted *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else{

//                   }
                  
//                   }
                  













    
    
    const setDisplayBlock = ()=>{
        // console.log("fddddddddddddddddd")
       let data1 =  document.getElementById("dispaly-block")
       if(data1.style.display === "none"){
        data1.style.display = "block"
       }
       else{
        data1.style.display = "none"
       }
    }


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}



const addtocartproduct8 = (productDetail) =>{
  swal("Update!", "Item Save!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }


  }


      

}
const setChan = (i) =>{
  console.log(i)
   if(      document.getElementById("categ"+i).style.display  ==="block")
  document.getElementById("categ"+i).style.display  ="none"
  else
  document.getElementById("categ"+i).style.display  ="block"
}
const setChan1 = (i,data) =>{
  //  let  e =  []
  //   for (let s = 0; s < SubCategories.length; s++) {
  //     if(data === SubCategories[s].MainCategories)
  //     e.push( SubCategories[s])
  //   }
  //   console.log(i,e)
  //   // console.log(a)
  //   for (let a = 0; a < e.length; a++) {
  //     console.log(a)
  //      document.getElementById("cate"+a).style.display  ="none"

  //    }
  console.log(document.getElementById("cate"+i),i)
     if(      document.getElementById("cate"+i).style.display  ==="block"){
      console.log("yes")
      document.getElementById("cate"+i).style.display  ="none"
    }
    else{
      console.log("yes1")

      document.getElementById("cate"+i).style.display  ="block"
    }
        let a =  []
        SubCategories.map((res1,j)=>{
          if(data === res1.MainCategories){
            a.push(j)
          }
        })
        console.log(a)
    if(a[0] !== i){
        document.getElementById("cate"+a[0]).style.display  ="none"
      }
    // if(i !== 0){
  }
const setChan2 = (i) =>{
  console.log(i)
   if(      document.getElementById("categp"+i).style.display  ==="block")
  document.getElementById("categp"+i).style.display  ="none"
  else
  document.getElementById("categp"+i).style.display  ="block"
}
const setChan3 = (i,data) =>{
  // for (let a = 0; a < SubCategories.length; a++) {
  //  console.log(a)
  //   document.getElementById("catep"+a).style.display  ="none"

  // }
  console.log(i)
   if(      document.getElementById("catep"+i).style.display  ==="block")
  document.getElementById("catep"+i).style.display  ="none"
  else
  document.getElementById("catep"+i).style.display  ="block"

  let a =  []
  SubCategories.map((res1,j)=>{
    if(data === res1.MainCategories){
      a.push(j)
    }
  })
  console.log(a)
if(a[0] !== i){
  document.getElementById("catep"+a[0]).style.display  ="none"
}
}
const setChang677 = () =>{
  console.log( document.getElementById("navbar-vertical1"))
  if( document.getElementById("navbar-vertical1").style.display === "none")
  document.getElementById("navbar-vertical1").style.display = "block"
  else
  document.getElementById("navbar-vertical1").style.display = "none"
}
        return (
       
   <div>
    
        
    <div className="container-fluid">
          <div className="row px-xl-5">
            <div className="col-lg-3 d-none d-lg-block"  style={{paddingRight : "0px"}}>
            <a className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100" data-toggle="collapse" href="#navbar-vertical" aria-expanded="false" style={{height: '37px', marginTop: '-1px', padding: '0 30px'}} onClick={()=>setChang677()}>
                <h6 className="m-0" style={{padding:"0px"}}>Categories</h6>
                <KeyboardArrowDownIcon />
              </a>
              <nav className="collapse navbar navbar-vertical navbar-light align-items-start p-0 border-top-0 border-bottom-0" id="navbar-vertical1" style={{position: "absolute",zIndex: "99999"}}>
                
                
                
                
                
                
                <div className="navbar-nav w-100" style={{height: '410px',overflow:"scroll"}}>
 







                  {MainCatogories.map((res,i)=>{
                    return (
                      <>
                      
                     
                      <a href className="nav-item nav-link" ><Link  to={"/categories/"+res.MainCategories+"/all-products"}>{res.MainCategories} </Link>
                       {Cate1[i] === "1" ?
<KeyboardArrowDownIcon onClick={()=>setChan(i)}/>
: ""
                       }
                       
                       
                       </a>                     
                     <div className="nav-item dropdown" style={{display : "none"}} id={"categ"+i}>
                      {  SubCategories.map((res1,j)=>{
                        // console.log(res1.MainCategories , res.MainCategories)
                        if(res1.MainCategories === res.MainCategories){
                          return (
                            <>
                            <a className="nav-link" data-toggle="dropdown" style={{padding  :"5px 30px"}}> <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/all-products"}> {res1.CategoriesName}</Link><KeyboardArrowDownIcon  onClick={()=>setChan1(j)}/> </a>
                        <div className="dropdown-menu rounded-0 w-100 m-0" id={"cate"+j}>
                        {SubClassCategories.map((res2,k)=>{
                        // console.log(res2)
                        if(res2.MainCategories === res.MainCategories && res2.CategoriesName === res1.CategoriesName){
                          return (
                            <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res2.Second_Categories+"/all-products"} className="dropdown-item" style={{padding  :"5px 40px"}}>{res2.Second_Categories}</Link>


                          )
                        } 
                      })
                    }
                       
                        {/* <a href className="dropdown-item">Men's Dresses</a>
                        <a href className="dropdown-item">Women's Dresses</a>
                        <a href className="dropdown-item">Baby's Dresses</a> */}
                      </div>
                            </>
                    
                          )
                        }
                        })
                      }
                      </div>
                     
                      </>
                    )
                  })

                  }
               















                </div>
























              </nav>
            </div>
            
              </div>
              </div>
        {/* Navbar End */}
        {/* Page Header Start */}
        <div className="container-fluid bg-secondary mb-3">
            <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '91px'}}>
              <h1 className="font-weight-semi-bold text-uppercase mb-3"style={{color : "white"}}>{CategoriesName.categoriesName}</h1>
              {/* <div className="d-inline-flex"style={{color : "white"}}>
                <p className="m-0"><a href>Home</a></p>
                <p className="m-0 px-2">-</p>
                <p className="m-0">{CategoriesName.categoriesName}</p>
              </div> */}
            </div>
          </div>
        {/* Page Header End */}
        {/* Shop Start */}
        <div className="container-fluid">
          <div className="row px-xl-5">
            {/* Shop Sidebar Start */}
            
            {/* Shop Sidebar End */}
            {/* Shop Product Start */}
            <div className="col-lg-12 col-md-12">
              <div className="row pb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center">
                    {/* <form action>
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search by name" />
                        <div className="input-group-append">
                          <span className="input-group-text bg-transparent text-primary">
                          <SearchIcon />
                          </span>
                        </div>
                      </div>
                    </form> */}
                    <select id="basic" className="selectpicker show-tick form-control" data-placeholder="$ USD" style={{width : "110px"}} onChange={(e)=>setSortPrice1(e.target.value)}>
                          <option data-display="Select">Nothing</option>
                          {/* <option value={1}>Popularity</option> */}
                          <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                          {/* <option value={4}>Best Selling</option> */}
                        </select>
                  </div>
                </div>

                {Subproduct.map((res,i)=>{
                if(res.Course_Sub_Catagories !== ""){
                  return(
  <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                <div className="card product-item border-0 mb-4">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/>
                  </Link></div>
                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                    <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                    {res.Product_Price_Discounted ? 
  <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
  :
  <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
  
                    }                  </div>
                  </div></Link>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                    <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                  </div>
                </div>
              </div>
  
                  )
              }
              if(res.Product_Sub_Catagories !== ""){
                console.log("323")
                return(
                  <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                                <div className="card product-item border-0 mb-4">
                                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/></Link>
                                  </div>
                                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                    <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                                    <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                                    {res.Product_Price_Discounted ? 
                  <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
                  :
                  <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
                  
                                    }                  </div>
                                  </div></Link>
                                  <div className="card-footer d-flex justify-content-between bg-light border">
                                    <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                                    <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                                  </div>
                                </div>
                              </div>
                  
                                  )
              }
              else{
                return(
                  <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                                <div className="card product-item border-0 mb-4">
                                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                    <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/></Link>
                                  </div>
                                  <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                    <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                                    <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                                    {res.Product_Price_Discounted ? 
                  <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
                  :
                  <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
                  
                                    }                  </div>
                                  </div></Link>
                                  <div className="card-footer d-flex justify-content-between bg-light border">
                                    <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                                    <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                                  </div>
                                </div>
                              </div>
                  
                                  )
              }
              })
                
              }

              
            



              

                {/* <div className="col-12 pb-1">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center mb-3">
                      <li className="page-item disabled">
                        <a className="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">«</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      <li className="page-item active"><a className="page-link" href="#">1</a></li>
                      <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">»</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
            {/* Shop Product End */}
          </div>
        </div>
        {/* Shop End */}
      
        {/* Back to Top */}
        <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up" /></a>
      </div>

        )
    }



export default SubCategoriesProduct;