import React, { useState , useEffect } from 'react';

import swal from 'sweetalert';

import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import logo1 from '../assets/images/logo1.png';

const Card = (props)=> {
    // const [price , setPrice] = useState(1)
    const [cart , setCart] = useState([])
    const [total , settotal] = useState(0)
    const [total1 , settotal1] = useState(0)
    const [price , setPrice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [peice , setpeice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [discount , setdiscount] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [promo , setpromo] = useState("")
    const [dis , setdis] = useState(true)
    const [totalpro , settotalpro] = useState(0)
    const [discount6 , setdiscount6] = useState({})
    const [total2 , settotal2] = useState(0)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])

    useEffect(() =>{
        window.scrollTo(0, 0)

        setpromo(JSON.parse(localStorage.getItem("discount")) ? JSON.parse(localStorage.getItem("discount")).name : "")
        fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          console.log(res3);
          // setMainCatogories(res3)
          

          let dat = []
          res3.map((res55,c)=>{
              dat.push(res55)
          })
          // console.log(SubClassCategories,asse)
          console.log(dat)
          let data = dat.sort((a, b) => {
              return a.sort - b.sort;
          });
          setMainCatogories(data)
          
      })
      fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     } ,
    })
    .then(res2=>res2.json())
    .then(res3=>{
        // console.log(res3);
        // const Data1 = res3.filter((res4,i)=>{
        //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
        //     return res4
        // })
        // console.log(Data1,res3)
        setSubCategories(res3)
        // setSubCategoriesname(res3)
        fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res12=>res12.json())
      .then(res13=>{
        let a = []
        // console.log(res3)
        for (let i = 0; i < res13.length; i++) {
        for (let j = 0; j < res3.length; j++) {
          // console.log(res3[j].MainCategories , res13[i].MainCategories)
          if(res3[j].MainCategories === res13[i].MainCategories){
            // a.push("1")
// console.log(i,j)
            a[i] = "1"
            break
          }
          else{
            a[i] = "0"
            // a.pusH(("0"))
          }
          
        }
        }
        // console.log(a)
        setCate1(a)
      })
    })


    fetch("https://halal-dropship-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://halal-dropship-main-back.vercel.app/AllCreateDisCountPrice",{
          method: "GET",
          headers :  {
          "Content-Type" : "application/json" , 
      } ,
      })
      .then(res7=>res7.json())
      .then(res8=>{
       setdiscount(res8)
       let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
       settotal1(data5)
       settotal2(data5)
      })
      if(JSON.parse(localStorage.getItem("discount")) ){
        setdiscount6(JSON.parse(localStorage.getItem("discount")))
      }
      else{
        setdiscount6({fg : "3"})
      }
       const Inter = setInterval(()=>{
            let data =  JSON.parse(localStorage.getItem("Cart")) 
            if(data)
           setCart(data)
           else      setCart(0)

           let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
           settotal1(data5)
           settotal2(data5)
          


           if(JSON.parse(localStorage.getItem("discount")) ){
             setdiscount6(JSON.parse(localStorage.getItem("discount")))
            // setdiscount(JSON.parse(localStorage.getItem("discount")))
            var d = document.getElementById("discount")
            var d1 = document.getElementById("discount1")
            if(JSON.parse(localStorage.getItem("discount")).discount > 1){
                d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount
                // document.getElementById("ci11").style.visibility = "visible"
                d1.innerHTML = "Coupon Discount : "
                // settotal1( total1 - JSON.parse(localStorage.getItem("discount")).discount)
            }
            else{
                d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
                d1.innerHTML = "Coupon Discount : "
                // settotal1(total1*JSON.parse(localStorage.getItem("discount").discount1)/100)

            }
        }
        else{
          var d = document.getElementById("discount")

          d.innerHTML = "No Discount" 

        }
        },3000)

        let data =  JSON.parse(localStorage.getItem("Cart")) 
        if(data)
       { 
           setCart(data)
           data.map((res,i)=>{
            peice[i] = res.Pieces
               price[i] = res.Pieces
           })
           console.log(price,peice)
        }
        else      setCart(0)

 if (data) {
            var j = 0
            data.map((item,i)=>{
               j = item.Total_Product_Price + j
            })
            settotal1(j)
            localStorage.setItem("CartPrice" ,JSON.stringify(j))
        }
        else{
            settotal1(0)

        }
        // console.log(total,cart,total1)
        // var t = document.getElementById("subtotal")
        // t.innerHTML = "Rs "+j
        // var f = document.getElementById("total")
        // f.innerHTML = "Rs " +j
      

        return () => {
        //   document.getElementById("get1").classList.remove("is-active");
        //   document.getElementById("get2").style.display = "none";
          clearInterval(Inter);
          updateCard1()
        }

  },[])
  
  const removeDatas = (item) =>{
 
    let data1;
    if(!item.Size){
         data1 = cart.filter((res,i)=>{
            return res._id !== item.id
        })
    }
    else{
         data1 = cart.filter((res,i)=>{
            return res.Size !== item.Size
        })
    }
    localStorage.setItem("Cart" ,JSON.stringify(data1))
        var j = 0
    data1.map((res,i)=>{
         j += res.Total_Product_Price
    })
    if ( j !== 0){
        localStorage.setItem("CartPrice",JSON.stringify( j))
        settotal1(j)
    }
    else{
        localStorage.setItem("CartPrice",JSON.stringify( 0))
        settotal1(0)
    }
    
    
  }


  const minus=(e)=>{
    if(peice[e] > 1)
    peice[e] = (peice[e] - 1)
    // console.log(peice[e],peice);
    if(dis)
    setdis(true)
    else setdis(false)}
const plus=(e)=>{
    if(peice[e] < 100)
    peice[e] = (peice[e] + 1)
    // console.log(peice[e],peice);
    if(dis)
    setdis(true)
    else setdis(false)


}










const updateCard = (id) =>{
    localStorage.removeItem("CartPrice")
      let data3 =  JSON.parse(localStorage.getItem("Cart")) 
      let arr = []
      arr = peice
  
      const pro = data3.map((res,i)=>{
        if(res.Size_Discounted.length === 0){
            res.Stock_Quantity.map((res1,j)=>{
                if(res.Size === res1){
                    return {
                        ...res , Pieces : arr[i] , Total_Product_Price : res.Price[j] * arr[i] 
                    }
                }
            })
        }
//         if(res.Size_Discounted.length > 0){
//         res.Stock_Quantity.map((res1,j)=>{
//             if(res.Size === res1){
//           return {
//               ...res , Pieces : arr[i] , Total_Product_Price : res.Size_Discounted * arr[i] 
//           }
//         }
//     })
// }
        if(res.Product_Price_Discounted)
          return {
              ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price_Discounted * arr[i] 
          }
          else 
          return {
              ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price * arr[i] 
          }
      })
      console.log(pro)
      setCart(pro)
     localStorage.removeItem("Cart")
      localStorage.setItem("Cart",JSON.stringify(pro))
      var j = 0
      pro.map((item,i)=>{
        console.log(item.Total_Product_Price)
         j = item.Total_Product_Price + j
      })
      settotal1(j)
      localStorage.setItem("CartPrice" ,JSON.stringify(j))
      swal("Update!", "Your Cart is Updated!", "success");
  
      // props.history.push("/card")
          
      // }
      
    }


const updateCard1 = (id) =>{
    localStorage.removeItem("CartPrice")
      let data3 =  JSON.parse(localStorage.getItem("Cart")) 
      let arr = []
      arr = peice
  if(data3){
    const pro = data3.map((res,i)=>{
        if(res.Size_Discounted.length === 0){
            res.Stock_Quantity.map((res1,j)=>{
                if(res.Size === res1){
                    return {
                        ...res , Pieces : arr[i] , Total_Product_Price : res.Price[j] * arr[i] 
                    }
                }
            })
        }
//         if(res.Size_Discounted.length > 0){
//         res.Stock_Quantity.map((res1,j)=>{
//             if(res.Size === res1){
//           return {
//               ...res , Pieces : arr[i] , Total_Product_Price : res.Size_Discounted * arr[i] 
//           }
//         }
//     })
// }
        if(res.Product_Price_Discounted)
          return {
              ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price_Discounted * arr[i] 
          }
          else 
          return {
              ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price * arr[i] 
          }
      })
      console.log(pro)
      setCart(pro)
     localStorage.removeItem("Cart")
      localStorage.setItem("Cart",JSON.stringify(pro))
      var j = 0
      pro.map((item,i)=>{
        console.log(item.Total_Product_Price)
         j = item.Total_Product_Price + j
      })
      settotal1(j)
      localStorage.setItem("CartPrice" ,JSON.stringify(j))
      // swal("Update!", "Your Cart is Updated!", "success");
  
      // props.history.push("/card")
          
      // }
  }
      
      
    }
  
    const setChan = (i) =>{
      console.log(i)
       if(      document.getElementById("categ"+i).style.display  ==="block")
      document.getElementById("categ"+i).style.display  ="none"
      else
      document.getElementById("categ"+i).style.display  ="block"
    }
    const setChan1 = (i,data) =>{
      //  let  e =  []
      //   for (let s = 0; s < SubCategories.length; s++) {
      //     if(data === SubCategories[s].MainCategories)
      //     e.push( SubCategories[s])
      //   }
      //   console.log(i,e)
      //   // console.log(a)
      //   for (let a = 0; a < e.length; a++) {
      //     console.log(a)
      //      document.getElementById("cate"+a).style.display  ="none"
   
      //    }
      console.log(document.getElementById("cate"+i),i)
         if(      document.getElementById("cate"+i).style.display  ==="block"){
          console.log("yes")
          document.getElementById("cate"+i).style.display  ="none"
        }
        else{
          console.log("yes1")
  
          document.getElementById("cate"+i).style.display  ="block"
        }
            let a =  []
            SubCategories.map((res1,j)=>{
              if(data === res1.MainCategories){
                a.push(j)
              }
            })
            console.log(a)
        if(a[0] !== i){
            document.getElementById("cate"+a[0]).style.display  ="none"
          }
        // if(i !== 0){
      }
    const setChan2 = (i) =>{
      console.log(i)
       if(      document.getElementById("categp"+i).style.display  ==="block")
      document.getElementById("categp"+i).style.display  ="none"
      else
      document.getElementById("categp"+i).style.display  ="block"
    }
    const setChang677 = () =>{
      console.log( document.getElementById("navbar-vertical1"))
      if( document.getElementById("navbar-vertical1").style.display === "none")
      document.getElementById("navbar-vertical1").style.display = "block"
      else
      document.getElementById("navbar-vertical1").style.display = "none"
    }
    const setChan3 = (i,data) =>{
      // for (let a = 0; a < SubCategories.length; a++) {
      //  console.log(a)
      //   document.getElementById("catep"+a).style.display  ="none"
    
      // }
      console.log(i)
       if(      document.getElementById("catep"+i).style.display  ==="block")
      document.getElementById("catep"+i).style.display  ="none"
      else
      document.getElementById("catep"+i).style.display  ="block"
    
      let a =  []
      SubCategories.map((res1,j)=>{
        if(data === res1.MainCategories){
          a.push(j)
        }
      })
      console.log(a)
    if(a[0] !== i){
      document.getElementById("catep"+a[0]).style.display  ="none"
    }
    }
    const setCouopon = () =>{
     
        var d = document.getElementById("discount")
        var d1 = document.getElementById("discount1")
        const the = discount.filter((res,i)=> res.name === promo)
        console.log(the)
        if(the.length === 0){
          swal("Please enter a correct promo code");
          document.getElementById("grand-total").innerHTML = total1
          localStorage.removeItem("discount")
          return
        }
        if(the[0].discount === null &&the[0].discount1 === null){
          swal("Please enter a correct promo code");
          document.getElementById("grand-total").innerHTML = total1
          localStorage.removeItem("discount")
          return
          
        }
        if(the.length > 0){
      
          setdiscount6(the[0])
          if( the[0].discount > 1){
            settotalpro((total1 -total1 * the[0].discount)/100)
            // settotal1((total1 * the[0].discount1)/100)
            localStorage.setItem("discount",JSON.stringify(the[0]))
            // d.innerHTML = "Rs " +the[0].discount
            d1.innerHTML = "Discount : "
            swal("Update!","Successfully your Promo Applied ", "success");
            
          }
          else{
            
            console.log(total1 -(total1 * the[0].discount1)/100)
            settotalpro((total1 -(total1 * the[0].discount1)/100))
            // settotal1((total1 * the[0].discount1)/100)
            // console.log(total1 , (total1/the[0].discount1))
            localStorage.setItem("discount",JSON.stringify(the[0]))
            // d.innerHTML = " " +the[0].discount1 +" % Off"
            d1.innerHTML = "Discount : "
            swal("Update!","Successfully your Promo Applied ", "success");
      
          }
        }
        else{
          swal("Please enter a correct promo code");
        }
          
        }
   
        return (
            <div>
            
 
            <div className="container-fluid">
          <div className="row border-top px-xl-5">
            <div className="col-lg-3 d-none d-lg-block"  style={{paddingRight : "0px"}}>
            <a className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100" data-toggle="collapse" href="#navbar-vertical" aria-expanded="false" style={{height: '37px', marginTop: '-1px', padding: '0 30px'}} onClick={()=>setChang677()}>
                <h6 className="m-0" style={{padding:"0px"}}>Categories</h6>
                <KeyboardArrowDownIcon />
              </a>
              <nav className="collapse navbar navbar-vertical navbar-light align-items-start p-0 border-top-0 border-bottom-0" id="navbar-vertical1" style={{position: "absolute",zIndex: "99999"}}> 
                
                
                
                
                
                <div className="navbar-nav w-100" style={{height: '410px',overflow:"scroll"}}>
 







                  {MainCatogories.map((res,i)=>{
                    return (
                      <>
                      
                     
                      <a href className="nav-item nav-link" ><Link  to={"/categories/"+res.MainCategories+"/all-products"}>{res.MainCategories} </Link>
                       {Cate1[i] === "1" ?
<KeyboardArrowDownIcon onClick={()=>setChan(i)}/>
: ""
                       }
                       
                       
                       </a>                     
                     <div className="nav-item dropdown" style={{display : "none"}} id={"categ"+i}>
                      {  SubCategories.map((res1,j)=>{
                        // console.log(res1.MainCategories , res.MainCategories)
                        if(res1.MainCategories === res.MainCategories){
                          return (
                            <>
                            <a className="nav-link" data-toggle="dropdown" style={{padding  :"5px 30px"}}> <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/all-products"}> {res1.CategoriesName}</Link>
                            {Cate2[j] === "1" ?
                            <KeyboardArrowDownIcon  onClick={()=>setChan1(j,res.MainCategories)}/>
: ""
                       }                            </a>
                        <div className="dropdown-menu border-0 rounded-0 w-100 m-0" id={"cate"+j}>
                        {SubClassCategories.map((res2,k)=>{
                        // console.log(res2)
                        if(res2.MainCategories === res.MainCategories && res2.CategoriesName === res1.CategoriesName){
                          return (
                            <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res2.Second_Categories+"/all-products"} className="dropdown-item" style={{padding  :"5px 40px"}}>{res2.Second_Categories}</Link>


                          )
                        } 
                      })
                    }
                       
                        {/* <a href className="dropdown-item">Men's Dresses</a>
                        <a href className="dropdown-item">Women's Dresses</a>
                        <a href className="dropdown-item">Baby's Dresses</a> */}
                      </div>
                            </>
                    
                          )
                        }
                        })
                      }
                      </div>
                     
                      </>
                    )
                  })

                  }
               















                </div>
























              </nav>
            </div>
            
          </div>
        </div>
        {/* Navbar End */}
        {/* Page Header Start */}
        <div className="container-fluid bg-secondary">
          <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '91px'}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3"style={{color : "white"}}>Shopping Cart</h1>
            {/* <div className="d-inline-flex"style={{color : "white"}}>
              <p className="m-0"><a href>Home</a></p>
              <p className="m-0 px-2">-</p>
              <p className="m-0">Shopping Cart</p>
            </div> */}
          </div>
        </div>
        {/* Page Header End */}
        {/* Cart Start */}
        <div className="container-fluid pt-5">
          <div className="row px-xl-5">
            <div className="col-lg-8 table-responsive mb-5">
              <table className="table table-bordered text-center mb-0">
                <thead className="bg-secondary text-dark">
                  <tr>
                    <th>Products</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody className="align-middle">
                  {
                                        cart.length >= 1 ? cart.map((item,i)=>{
                                          return(
<tr>
                    <td className="align-middle" onClick={()=>props.history.push("/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id)} ><img src={item.Product_Image_Upload} alt="" style={{width: '50px'}} /> Colorful Stylish Shirt</td>
                    <td className="align-middle">
                    Rs {(item.Total_Product_Price).toFixed(0) /  item.Pieces }
                      
                      {/* $150 */}
                    
                    
                    </td>
                    <td className="align-middle">
                      <div className="input-group quantity mx-auto" style={{width: '86px'}}>
                        <div className="input-group-btn">
                          <button className="change-button-style1 btn-sm btn-minus" onClick={()=>minus(i)} style={{ border : "1px solid #285a73"}} >-
                          </button>
                        </div>
                        <input type="text" className="form-control form-control-sm text-center"  value={peice[i]} size={4} defaultValue={1} min={0} step={1} style={{height : "31px"}} />
                        <div className="input-group-btn">
                          <button className="change-button-style1 btn-sm btn-plus"  onClick={()=>plus(i)} style={{ border : "1px solid #285a73"}}>+
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="align-middle">Rs {(( item.Total_Product_Price).toFixed(0) /  item.Pieces)* peice[i]}</td>
                    <td className="align-middle" style={{ border : "1px solid #285a73"}} ><button className="btn-sm" style={{background : "transparent",border : "none" }}><DeleteIcon onClick={()=>removeDatas(item)} style={{color  :"#285a73"}}/></button></td>
                  </tr>

                                          )
                                        })
                                        : <tr><td colSpan="6"><div className="col-sm-12 col-lg-12 mb-12"><center> <h3> No Product in Cart</h3></center> </div></td></tr> 
                          }
                  
                </tbody>
              </table>
            </div>
            <div className="col-lg-4">
              <form className="mb-5" action>
                <div className="input-group">
                  <input type="text" className="form-control p-4" placeholder="Coupon Code"  value={promo} onChange={(e)=>setpromo(e.target.value)}/>
                  <div className="input-group-append">
                    <button className="btn" onClick={()=>setCouopon()} style={{color  : "white" , background : "#285a73"}}>Apply Coupon</button>
                  </div>
                </div>
              </form>
              <div className="card border-secondary mb-5">
                <div className="card-header bg-secondary border-0">
                  <h4 className="font-weight-semi-bold m-0"style={{color : "white"}}>Cart Summary</h4>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-3 pt-1">
                    <h6 className="font-weight-medium">Subtotal</h6>
                    <h6 className="font-weight-medium"> Rs. {total1 > 4? (total1).toFixed(0) :0 

}</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">Discount</h6>
                    <h6 className="font-weight-medium" id="discount"></h6>
                  </div>
                </div>
                <div className="card-footer border-secondary bg-transparent">
                  <div className="d-flex justify-content-between mt-2">
                    <h5 className="font-weight-bold">Total</h5>
                    <h5 className="font-weight-bold">
                               Rs.  
                            
                               {/* {
           discount6.discount ?
           " "+ (total2 - discount6.discount).toFixed(0)
            : ""
          }
        
           { discount6.discount1 ? 
           " "+((total2 -( total2 * discount6.discount1)/100)).toFixed(0) :
           ""
            } */}
            {/* {discount6.fg === "3" ?  */}
         { total2 > 6  ?(total2).toFixed(0): 0 }
            {/* : "" */}
        
            {/* } */}
                    
</h5>
                  </div>
                  <button className="btn btn-block my-3 py-3" onClick={()=>props.history.push("/checkout")} style={{color  : "white" , background : "#285a73"}}>Proceed To Checkout</button>
                </div>
              </div>
            </div>
          </div>
        </div>










































       
            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
            </div>
        )
    }


export default Card;