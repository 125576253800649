import React, {useState , useEffect} from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

const Checkout = (props) => {
    const [fname , setfName ] = useState("")
    // const [Lname , setLName ] = useState("")
    const [Address , setAddress ] = useState("")
    const [Address1 , setAddress1 ] = useState("")
    // const [ZipPostal , setZipPostal ] = useState("")
    const [Email , setEmail ] = useState("")
    const [SellPrice , setSellPrice ] = useState("")
    const [Phone , setPhone ] = useState("")
    const [Phone1 , setPhone1 ] = useState("")
    const [City , setCity ] = useState("")
    const [CODAmount , setCODAmount ] = useState(0)
    const [ServicesCharge , setServicesCharge ] = useState(0)
    const [ServicesCharge1 , setServicesCharge1 ] = useState(0)
    const [admindataall , setadmindataall ] = useState(0)
    const [BusinessName , setBusinessName ] = useState("")
    const [StoreName , setStoreName ] = useState("")
    const [Order_Notes , setOrder_Notes ] = useState("")
    const [cart , setCart] = useState([])
    const [tot , setot] = useState(0)
    const [total , settotal] = useState(0)
    const [doctor , setdoctor] = useState(0)
    const [Num1111 , setNum1111] = useState(0)
    const [discount , setdiscount ] = useState({})

    const [range1,setRange1] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [range,setRange] = useState([])
    const [range2,setRange2] = useState([])

    const [display , setdisplay] = useState(true)
    const [play , setplay] = useState(false)
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")




    useEffect(() =>{
        window.scrollTo(0, 0)
        if(! JSON.parse(localStorage.getItem("user main")) )  {
            props.history.push("/user/login")
        }  
        
        // var data1 =  JSON.parse(localStorage.getItem("user main"))
        // if(! data1){
        //         props.history.push("/user/login")

        // }
        var data =  JSON.parse(localStorage.getItem("Cart")) 
        // var data1 =  JSON.parse(localStorage.getItem("CartPrice")) 
        if (data) {
            setCart(data)
            
            data.map((res,i)=>{
                range1[i] = res.ProductCreater
                range.push(res.ProductCreater)
                range2.push(res)
            })
            
            setRange1(range1)
            
            setRange(range)
            let hhh =[... new Set(range)]
            setRange(hhh)
            // console.log(range1,range,hhh)
            let fg =  []
            // let fg1 =  []
            for (let i = 0; i < hhh.length; i++) {
                    let ab = parseInt(Math.random()*4)
                let ab1 = parseInt(Math.random()*8)
                let ab2 = parseInt(Math.random()*1)
                let ab3 = parseInt(Math.random()*7)
                let ab4 = parseInt(Math.random()*3)
                // let ab5 = parseInt(Math.random()*99)
                // let ab6 = parseInt(Math.random()*53)
                let leng = ab+""+ab1+""+ab2+""+ab3+""+ab4
                fg.push(leng)

            }
            for (let i = 0; i < range2.length; i++) {
                hhh.map((res,u)=>{
                    if(range2[i].ProductCreater === res){
                        range2[i] = {
                            ...range2[i] , otpId : fg[u]
                        }
                        // fg1.push({
                        //     ...range2[i] , otpId : fg[u]
                        // })
                    }
              
                })
            }
            console.log(range2)
            setCart(range2)

            // setot(JSON.parse(localStorage.getItem("CartPrice")) )

            if( JSON.parse(localStorage.getItem("user main")) )
            {
            setfName(JSON.parse(localStorage.getItem("user main")).user)
            setAddress(JSON.parse(localStorage.getItem("user main")).address)
            setEmail(JSON.parse(localStorage.getItem("user main")).email)
            setPhone(JSON.parse(localStorage.getItem("user main")).phone)
            } 
  fetch("https://halal-dropship-main-back.vercel.app/AllUserCheckoutData",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res181=>res181.json())
.then(res191=>{
    // setNum1111(res191.length)
    let date = new Date()
    let day= date.getDate() 
    let month= (date.getUTCMonth()+1)
    let year= date.getUTCFullYear()
    if(date.getDate() < 10) day = "0"+ date.getDate()
    if(date.getUTCMonth()+1 < 10) month = "0"+ (date.getUTCMonth()+1)
    
    let c = day +""+month+ ""+(year -2000)
    const f = res191.filter((res,i)=>res.Date === c)
    console.log(f,c)
    setNum1111(f.length)

})
  fetch("https://halal-dropship-main-back.vercel.app/admindata",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res18=>res18.json())
.then(res19=>{
    setdoctor(res19[0].condition)
    setServicesCharge(res19[0].ServicesCharge)
    console.log(res19[0].ServicesCharge)
    console.log(res19[0].DeliveryCharges,JSON.parse(localStorage.getItem("CartPrice")));
  setadmindataall(res19[0].DeliveryCharges)

  let j = 0
  j = res19[0].DeliveryCharges 

  if(JSON.parse(localStorage.getItem("CartPrice")) < res19[0].condition){
                // j = res19[0].DeliveryCharges + res19[0].ServicesCharge
            }
            else{
                // j = 0
            }
            data.map((item,i)=>{
               j = item.Total_Product_Price + j
            //    if (item.doctor_prescription){
            //     localStorage.setItem("doctor",JSON.stringify(true))
            //    }
            })
            // var doc =  JSON.parse(localStorage.getItem("doctor")) 
            // setdoctor(doc)
            let b = j / res19[0].ServicesCharge
            setServicesCharge1(j / res19[0].ServicesCharge)
            j = j + b
            if(JSON.parse(localStorage.getItem("discount")) ){
                setdiscount(JSON.parse(localStorage.getItem("discount")))
                var d = document.getElementById("discount")
                if(JSON.parse(localStorage.getItem("discount")).discount > 1){
                    d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount
                    document.getElementById("ci11").style.visibility = "visible"
                    j = j - JSON.parse(localStorage.getItem("discount")).discount
                }
                else{
                    d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount1 +" % ."
                    j = j - (j/JSON.parse(localStorage.getItem("discount")).discount1)

                }
            }


            setot(j)
            // var t = document.getElementById("subtotal")
            // t.innerHTML = "Rs "+j
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +(j).toFixed(0)

        })

        }
        // console.log(total,cart,j)
   else{
            // var t = document.getElementById("subtotal")
            // t.innerHTML = "Rs "+0
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +0
   }
  },[])
  

//   useEffect(() => {
  
//     return () => {
//         localStorage.removeItem("doctor")
//     }
//   }, [])


    const SubmitDataMessage = (e) =>{
        e.preventDefault()
        console.log(cart)
        // fetch("https://halal-dropship-main-back.vercel.app/admindata",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         }
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        // if ( JSON.parse(localStorage.getItem("User"))  ){
            if (cart.length  >= 1 ){
                setdisplay(false)
        console.log("wddde");
               let j
        if(JSON.parse(localStorage.getItem("CartPrice")) < doctor){
                 j = admindataall
            }
            else{
                 j = 0
            }
            console.log(j,tot)
            let date = new Date()
            let f= date.getDate() +" "+(date.getUTCMonth()+1)+ " "+date.getUTCFullYear()
            let day= date.getDate() 
            let month= (date.getUTCMonth()+1)
            let year= date.getUTCFullYear()
            year = year - 2000

            if(date.getDate() < 10) day = "0"+ date.getDate()
            if(date.getUTCMonth()+1 < 10) month = "0"+ (date.getUTCMonth()+1)

            let c = day +""+month+ ""+year

            console.log(day , month , year,c,fname,
                            Address ,
                            Address1 ,
                            // UserId : JSON.parse(localStorage.getItem("user main"))._id ,
                            // ZipPostal ,
                            Email ,
                            Phone ,
                            Order_Notes,
                            discount ,
                            j,
                             cart,
                             Num1111,
                             c  )
                fetch("https://halal-dropship-main-back.vercel.app/UsergetDatafromclient",{
                        method: "POST",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                        body : JSON.stringify({
                            fname,
                            Address ,
                            Phone ,
                            // Phone1 ,
                            City ,
                            SellPrice ,
                            // Address1 ,
                            // CODAmount ,
                            // BusinessName ,
                            StoreName ,
                            UserId : JSON.parse(localStorage.getItem("user main"))? JSON.parse(localStorage.getItem("user main"))._id :  "",
                            // ZipPostal ,
                            Email ,
                            Order_Notes,
                            Total :  tot.toFixed(0) ,
                            OrderTotal :  JSON.parse(localStorage.getItem("CartPrice")) ,
                            // TotalAmount  : JSON.parse(localStorage.getItem("CartPrice")) ,
                            discount ,
                            DeliveryCharges:j,
                            Order : cart,
                            Num123 : Num1111,
                            Date1 : c ,

                        }),
                    })
                    .then(res=>res.json())
                    .then((res2)=>{        
                    if(res2.Error){
                        swal("Plz Refresh And try Again the Order");
                     }
                     else{
                        setdisplay(true)
                        swal("Successfully Submit your Order!");
                        // fetch("https://halal-dropship-main-back.vercel.app/user-cart-order",{
                        //     method: "POST",
                        //     headers :  {
                        //         "Content-Type" : "application/json" , 
                        //     } ,
                        //     body : JSON.stringify({
                        //         Order : cart ,
                        //         user : JSON.parse(localStorage.getItem("User")) 
                        //     })
                        // })
                        // .then(res10=>res10.json())
                        // .then((res11)=>{ 
                        //     console.log(res11);
                        // })
                        // fetch("https://halal-dropship-main-back.vercel.app/user-cart-detail",{
                        //     method: "POST",
                        //     headers :  {
                        //         "Content-Type" : "application/json" , 
                        //     } ,
                        //     body : JSON.stringify({
                        //         Details : {
                        //             fname,
                        //             Lname ,
                        //             CName, 
                        //             Address ,
                        //             Address1 ,
                        //             StateCountry  ,
                        //             ZipPostal ,
                        //             Email ,
                        //             Phone ,
                        //             Date : c ,
                        //             Order_Notes,
                        //             Select_Country,
                        //         },
                        //         user : JSON.parse(localStorage.getItem("User")) 
                        //     })
                        // })
                        // .then(res6=>res6.json())
                        // .then((res7)=>{ 
                        //     console.log(res7);
                        // })

                            setfName ("")
                            // setLName ("" )
                            setAddress  ("")
                            setAddress1 ("" )
                            // setZipPostal ("" )
                            setEmail ("" )
                            setPhone ("" )
                            setOrder_Notes ("")
                        localStorage.removeItem("Cart")
                        localStorage.removeItem("doctor")
                        localStorage.removeItem("SearchData")
                        localStorage.removeItem("Data")
                        localStorage.removeItem("CartPrice")
                        localStorage.removeItem("CateProduct")
                        props.history.push(`/thankyou`)

                     }
                  })
                  .catch(err=>{
                    swal("There is an Error");
                })
            }
            else{
                swal("There is No Any Product in Your Cart");
                setTimeout(()=>{
                    
                    props.history.push(`/`)
                },2000)
            }
        // })
    }


    const setChanger4 = (e)=>{
        console.log(e)
        setCity(e)
    }
        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <Link to="/cart">Cart</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Checkout</strong></div>
                        </div>
                    </div>
                </div>
            <form onSubmit={(e)=>SubmitDataMessage(e)}>
                <div className="site-section">
                    <div className="container">
                        {/* <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="border p-4 rounded" role="alert">
                                    Returning customer? <Link to="/login">Click here</Link> to login
                                </div>
                            </div> */}
                        {/* </div> */}
                        <div className="row">
                            <div className="col-md-6 mb-5 mb-md-0">
                                <h2 className="h3 mb-3 text-black">Billing Details</h2>
                                <div className="p-3 p-lg-5 border">
                                    
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <label htmlFor="c_fname" className="text-black">Your Customer's Full Name <span className="text-danger">*</span></label>
                                            <input type="text" required value={fname} onChange={(e)=>setfName(e.target.value)}  className="form-control" id="c_fname" name="c_fname" placeholder="Enter Customer's Full Name "/>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label htmlFor="c_lname" className="text-black">Last Name <span className="text-danger">*</span></label>
                                            <input type="text" required value={Lname} onChange={(e)=>setLName(e.target.value)}  className="form-control" id="c_lname" name="c_lname" />
                                        </div> */}
                                    </div>

                                    

                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <label htmlFor="c_address" className="text-black">Your Customer's Address <span className="text-danger">*</span></label>
                                            <input type="text" required value={Address} onChange={(e)=>setAddress(e.target.value)}   className="form-control" id="c_address" name="c_address" placeholder="Street address" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <label htmlFor="c_address" className="text-black">Your Customer's City <span className="text-danger">*</span></label>
                                            <input type="text" required value={City} onChange={(e)=>setCity(e.target.value)}   className="form-control" id="c_address" name="c_address" placeholder="Enter City" />

{/* <select name="" id="" onChange={(e)=>setChanger4(e.target.value)}   className="form-control" >
        <option value="   Karachi  ">   Karachi  </option>
<option value="   Lahore    ">   Lahore    </option>
<option value="   Faisalabad    ">   Faisalabad    </option>
<option value="   Rawalpindi    ">   Rawalpindi    </option>
<option value="   Gujranwala    ">   Gujranwala    </option>
<option value="   Peshawar  ">   Peshawar  </option>
<option value="   Multan    ">   Multan    </option>
<option value="   Saidu Sharif  ">   Saidu Sharif  </option>
<option value="   Hyderabad City    ">   Hyderabad City    </option>
<option value="   Islamabad     ">   Islamabad     </option>
<option value="   Quetta     ">   Quetta    </option> 
<option value="   Bahawalpur   ">   Bahawalpur   </option>
<option value="   Sargodha  ">   Sargodha  </option>
<option value="   Sialkot City      ">   Sialkot City      </option>
<option value="   Sukkur    ">   Sukkur    </option>
<option value="   Larkana   ">   Larkana   </option>
<option value="   Chiniot   ">   Chiniot   </option>
<option value="   Shekhupura    ">   Shekhupura    </option>
<option value="   Jhang City    ">   Jhang City    </option> 
<option value="   Dera Ghazi Khan   ">   Dera Ghazi Khan   </option>
<option value="   Gujrat    ">   Gujrat    </option>
<option value="   Rahimyar Khan     ">   Rahimyar Khan     </option>
<option value="   Kasur     ">   Kasur     </option>
<option value="   Mardan     ">  Mardan    </option> 
<option value="   Mingaora     ">   Mingaora     </option>
<option value="   Nawabshah     ">   Nawabshah     </option>
<option value="   Sahiwal       ">   Sahiwal       </option>
<option value="   Mirpur Khas       ">   Mirpur Khas       </option>
<option value="   Okara         ">   Okara         </option>
<option value="   Mandi Burewala        ">   Mandi Burewala        </option>
<option value="   Jacobabad         ">   Jacobabad         </option>
<option value="   Saddiqabad    ">   Saddiqabad    </option>
<option value="   Kohat     ">   Kohat     </option>
<option value="   Muridke       ">   Muridke       </option>
<option value="   Muzaffargarh      ">   Muzaffargarh      </option>
<option value="   Khanpur       ">   Khanpur       </option>
<option value="   Gojra         ">   Gojra         </option>
<option value="   Mandi Bahauddin       ">   Mandi Bahauddin       </option>
<option value="   Abbottabad        ">   Abbottabad        </option>
<option value="   Turbat        ">   Turbat        </option>
<option value="   Dadu          ">   Dadu          </option>
<option value="   Bahawalnagar          ">   Bahawalnagar          </option>
<option value="   Khuzdar           ">   Khuzdar           </option>
<option value="   Pakpattan    ">       Pakpattan             </option> 
<option value="   Tando Allahyar           ">   Tando Allahyar           </option>
<option value="   Ahmadpur East         ">   Ahmadpur East         </option>
<option value="   Vihari        ">   Vihari        </option>
<option value="   Jaranwala         ">   Jaranwala         </option>
<option value="   New Mirpur        ">   New Mirpur        </option>
<option value="   Kamalia           ">   Kamalia           </option>
<option value="   Kot Addu      ">   Kot Addu      </option>
<option value="   Nowshera      ">   Nowshera      </option>
<option value="   Swabi         ">   Swabi         </option>
<option value="   Khushab       ">   Khushab       </option>
<option value="   Dera Ismail Khan      ">   Dera Ismail Khan      </option>
<option value="   Chaman        ">   Chaman        </option>
<option value="   Charsadda         ">   Charsadda         </option>
<option value="   Kandhkot      ">   Kandhkot      </option>
<option value="   Chishtian         ">   Chishtian         </option>
<option value="   Hasilpur          ">   Hasilpur          </option>
<option value="   Attock Khurd          ">   Attock Khurd          </option>
<option value="   Muzaffarabad          ">   Muzaffarabad          </option>
<option value="   Mianwali              ">   Mianwali              </option>
<option value="   Jalalpur Jattan           ">   Jalalpur Jattan           </option>
<option value="   Bhakkar           ">   Bhakkar           </option>
<option value="   Zhob              ">   Zhob              </option>
<option value="   Dipalpur          ">   Dipalpur          </option>
<option value="   Kharian           ">   Kharian           </option>
<option value="   Mian Channun          ">   Mian Channun          </option>
<option value="   Bhalwal           ">   Bhalwal           </option>
<option value="   Jamshoro          ">   Jamshoro          </option>
<option value="   Pattoki       ">   Pattoki       </option>
<option value="   Harunabad             ">   Harunabad             </option>
<option value="   Kahror Pakka          ">   Kahror Pakka          </option>
<option value="   Toba Tek Singh        ">   Toba Tek Singh        </option>
<option value="   Samundri          ">   Samundri          </option>
<option value="   Shakargarh        ">   Shakargarh        </option>
<option value="   Sambrial          ">   Sambrial          </option>
<option value="   Shujaabad         ">   Shujaabad         </option>
<option value="   Hujra Shah Muqim      ">   Hujra Shah Muqim      </option>
<option value="   Kabirwala             ">   Kabirwala             </option>
<option value="   Mansehra      ">   Mansehra      </option>
<option value="   Lala Musa         ">   Lala Musa         </option>
<option value="   Chunian       ">   Chunian       </option>
<option value="   Nankana Sahib         ">   Nankana Sahib         </option>
<option value="   Bannu         ">   Bannu         </option>
<option value="   Pasrur        ">   Pasrur        </option>
<option value="   Timargara         ">   Timargara         </option>
<option value="   Parachinar        ">   Parachinar        </option>
<option value="   Chenab Nagar      ">   Chenab Nagar      </option>
<option value="   Gwadar            ">   Gwadar            </option>
<option value="   Abdul Hakim       ">   Abdul Hakim       </option>
<option value="   Hassan Abdal      ">   Hassan Abdal      </option>
<option value="   Tank          ">   Tank          </option>
<option value="   Hangu         ">   Hangu         </option>
<option value="   Risalpur Cantonment  " >  Risalpur Cantonment     </option>  
<option value="   Karak         ">   Karak         </option>
<option value="   Kundian       ">   Kundian       </option>
<option value="   Umarkot       ">   Umarkot       </option>
<option value="   Chitral       ">   Chitral       </option>
<option value="   Dainyor       ">   Dainyor       </option>
<option value="   Kulachi       ">   Kulachi       </option>
<option value="   Kalat         ">   Kalat         </option>
<option value="   Kotli          ">   Kotli         </option> 
<option value="   Gilgit        ">   Gilgit        </option>
<option value="   Narowal       ">   Narowal       </option>
<option value="   Khairpur Mir’s        ">   Khairpur Mir’s        </option>
<option value="   Khanewal      ">   Khanewal      </option>
<option value="   Jhelum        ">   Jhelum        </option>
<option value="   Haripur       ">   Haripur       </option>
<option value="   Shikarpur         ">   Shikarpur         </option>
<option value="   Rawala Kot        ">   Rawala Kot        </option>
<option value="   Hafizabad                 ">   Hafizabad                 </option>
<option value="   Lodhran       ">   Lodhran       </option>
<option value="   Malakand      ">   Malakand      </option>
<option value="   Attock City            ">  Attock City             </option> 
<option value="   Batgram       ">   Batgram       </option>
<option value="   Matiari           ">   Matiari           </option>
<option value="   Ghotki        ">   Ghotki        </option>
<option value="   Naushahro Firoz       ">   Naushahro Firoz       </option>
<option value="   Alpurai              ">   Alpurai              </option>
<option value="   Bagh      ">   Bagh      </option>
<option value="   Daggar ">   Daggar
<option value="   Leiah  ">   Leiah </option> </option>
<option value="   Tando Muhammad Khan ">   Tando Muhammad Khan </option>
<option value="   Chakwal ">   Chakwal </option>
<option value="   Badin ">   Badin </option>
<option value="   Lakki ">   Lakki </option>
<option value="   Rajanpur ">   Rajanpur </option>
<option value="   Dera Allahyar ">   Dera Allahyar </option>
<option value="   Shahdad Kot ">   Shahdad Kot </option>
<option value="   Pishin ">   Pishin </option>
<option value="   Sanghar ">   Sanghar </option>
<option value="   Upper Dir ">   Upper Dir </option>
<option value="   Thatta ">   Thatta </option>
<option value="   Dera Murad Jamali ">   Dera Murad Jamali </option>
<option value="   Kohlu ">   Kohlu </option>
<option value="   Mastung ">   Mastung </option>
<option value="   Dasu ">   Dasu </option>
<option value="   Athmuqam ">   Athmuqam </option>
<option value="   Loralai ">   Loralai </option>
<option value="   Barkhan ">   Barkhan </option>
<option value="   Musa Khel Bazar ">   Musa Khel Bazar </option>
<option value="   Ziarat ">   Ziarat </option>
<option value="   Gandava ">   Gandava </option>
<option value="   Sibi ">   Sibi </option>
<option value="   Dera Bugti ">   Dera Bugti </option>
<option value="   Eidgah ">   Eidgah </option>
<option value="   Uthal ">   Uthal </option>
<option value="   Khuzdar ">   Khuzdar </option>
<option value="   Chilas ">   Chilas </option>
<option value="   Panjgur ">   Panjgur </option>
<option value="   Gakuch ">   Gakuch </option>
<option value="   Qila Saifullah ">   Qila Saifullah </option>
<option value="   Kharan ">   Kharan </option>
<option value="   Aliabad ">   Aliabad </option>
<option value="   Awaran ">   Awaran </option>
<option value="   Dalbandin ">   Dalbandin </option>
</select> */}


                                        </div>
                                    </div>

                                    {/* <div className="form-group">
                                        <input type="text" value={Address1} onChange={(e)=>setAddress1(e.target.value)}  className="form-control" placeholder="Apartment, suite, unit etc. (optional)" />
                                    </div> */}

                                    {/* <div className="form-group row">
                                        
                                        <div className="col-md-12">
                                            <label htmlFor="c_postal_zip" className="text-black">Postal / Zip <span className="text-danger">*</span></label>
                                            <input type="number" required value={ZipPostal} onChange={(e)=>setZipPostal(e.target.value)}  className="form-control" id="c_postal_zip" name="c_postal_zip" />
                                        </div>
                                    </div> */}

                                    <div className="form-group row">
                                        {/* <div className="col-md-6">
                                            <label htmlFor="c_email_address" className="text-black">Email Address <span className="text-danger">*</span></label>
                                            <input type="email" required value={Email} onChange={(e)=>setEmail(e.target.value)} className="form-control" id="c_email_address" name="c_email_address" />
                                        </div> */}
                                        <div className="col-md-12">
                                            <label htmlFor="c_phone" className="text-black">Your Customer's Phone <span className="text-danger">*</span></label>
                                            <input type="number" required value={Phone} onChange={(e)=>setPhone(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Customer Phone Number" />
                                        </div>
                                        
                                    </div>
                                    <div className="form-group row">
                                        {/* <div className="col-md-6">
                                            <label htmlFor="c_phone" className="text-black">Phone Number<span className="text-danger">*</span></label>
                                            <input type="number" required value={Phone1} onChange={(e)=>setPhone1(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Phone Number" />
                                        </div> */}
                                        <div className="col-md-12">
                                            <label htmlFor="c_email_address" className="text-black">Email Address <span className="text-danger">*</span></label>
                                            <input type="email" required value={Email} onChange={(e)=>setEmail(e.target.value)} className="form-control" id="c_email_address" name="c_email_address"  placeholder="Enter Email" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                       
                                        <div className="col-md-12">
                                            <label htmlFor="c_phone" className="text-black">Sell Price<span className="text-danger">*</span></label>
                                            <input type="number" required value={SellPrice} onChange={(e)=>setSellPrice(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Total Amount To Receive From Customer " />
                                            {/* <label htmlFor="c_phone" className="text-black">Total Amount To Receive From Customer {SellPrice-tot  > 1 ? SellPrice-tot  : 0}</label> */}
                                            <label htmlFor="c_phone" className="text-black">Your Commission will be {SellPrice-tot  > 1 ? SellPrice-tot  : 0} on this particular order</label>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                       
                                        <div className="col-md-12">
                                            <label htmlFor="c_phone" className="text-black">Business Name<span className="text-danger">*</span></label>
                                            <input type="text" required value={BusinessName} onChange={(e)=>setBusinessName(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Enter Business Name" />
                                        </div>
                                    </div> */}
                                    {/* <div className="form-group row">
                                       
                                        <div className="col-md-12">
                                            <label htmlFor="c_phone" className="text-black">COD Amount<span className="text-danger">*</span></label>
                                            <input type="text" required value={CODAmount} onChange={(e)=>setCODAmount(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Amount Recieve from Customer" />
                                        </div>
                                    </div> */}
                                    <div className="form-group row">
                                       
                                        <div className="col-md-12">
                                            <label htmlFor="c_phone" className="text-black">Store Name<span className="text-danger">*</span></label>
                                            <input type="text" required value={StoreName} onChange={(e)=>setStoreName(e.target.value)}   className="form-control" id="c_phone" name="c_phone" placeholder="Enter Business Name" />
                                        </div>
                                    </div>









                                    <div className="form-group">
                                        <label htmlFor="c_order_notes" className="text-black">Order Notes</label>
                                        <textarea name="c_order_notes"  value={Order_Notes} onChange={(e)=>setOrder_Notes(e.target.value)} id="c_order_notes" cols="30" rows="5" className="form-control" placeholder="Write your notes here..."></textarea>
                                    </div>

                                   
                                    
                                </div>
                                
                            </div>
                            <div className="col-md-6">

                                

                            <div className="col-lg-12">
              <div className="card border-secondary mb-5">
                <div className="card-header bg-secondary border-0">
                  <h4 className="font-weight-semi-bold m-0"style={{color : "white"}}>Order Total</h4>
                </div>
                <div className="card-body">
                  <h5 className="font-weight-medium mb-3">Products</h5>
                  {
                                                    cart && cart.map((item,i)=>{
                                                        return(
                                                            <div className="d-flex justify-content-between">  
                                                                {item.Price[0] ?
        <p> <div>
        <span className='text-truncate' style={{color : "black",width: "142px"}}>{item.Product_Name}</span>
        <span style={{paddingLeft  :"30px"}}>{item.Size.length > 2 ? "Color : " :  "Size : "}  {item.Size}</span>
      </div></p>

 :
 <p className='text-truncate' style={{color : "black",width: "142px"}}> {item.Product_Name }</p>
} 

<p>x {item.Pieces}</p>
                    <p>Rs {(item.Total_Product_Price).toFixed(0)}</p>
                                                            </div>
                                                  
)
                                                    })
                                                }

                  {/* <div className="d-flex justify-content-between">
                    <p>Colorful Stylish Shirt 1  * 1</p>
                    <p>$150</p>
                    <p>$150</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Colorful Stylish Shirt 2</p>
                    <p>$150</p>
                    <p>$150</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Colorful Stylish Shirt 3</p>
                    <p>$150</p>
                    <p>$150</p>
                  </div> */}
                  <hr className="mt-0" />
                  <div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">Shipping</h6>
                    <h6 className="font-weight-medium">{ admindataall > 0 ?
                                                            "Rs : "+admindataall
                                                            : 
                                                            <b>Free</b>
                                                         }</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">Service charges</h6>
                    <h6 className="font-weight-medium">{ ServicesCharge > 0 ?
                                                            // "Rs : "+
                                                            ServicesCharge + " % "+" = Rs: "+ ServicesCharge1.toFixed(0) 
                                                            : 
                                                            <b>Free</b>
                                                         }</h6>
                  </div>
                  <div className="d-flex justify-content-between mb-3 pt-1">
                    <h6 className="font-weight-medium">Discount</h6>
                    <h6 className="font-weight-medium" id="discount"></h6>
                  </div>
                </div>
                <div className="card-footer border-secondary bg-transparent">
                  <div className="d-flex justify-content-between mt-2">
                    <h5 className="font-weight-bold">Total</h5>
                    <h5 className="font-weight-bold" id="total"></h5>
                  </div>
                </div>
              </div>
              <div className="card border-secondary mb-5">
                <div className="card-header bg-secondary border-0">
                  <h4 className="font-weight-semi-bold m-0"style={{color : "white"}}>Payment</h4>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <div className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input" name="payment" id="paypal" />
                      <label className="custom-control-label" htmlFor="paypal">Cash on Delivery</label>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <div className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input" name="payment" id="directcheck" />
                      <label className="custom-control-label" htmlFor="directcheck">Direct Check</label>
                    </div>
                  </div>
                  <div className>
                    <div className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input" name="payment" id="banktransfer" />
                      <label className="custom-control-label" htmlFor="banktransfer">Bank Transfer</label>
                    </div>
                  </div> */}
                </div>
                <div className="card-footer border-secondary bg-transparent">
                  <button className="btn btn-lg btn-block font-weight-bold my-3 py-3" onClick={()=>SubmitDataMessage()}  style={{color  : "white" , background : "#285a73"}}>Place Order</button>
                </div>
              </div>
            </div>

                            </div>
                        </div>

                    </div>
                </div>
            </form>

            </div>
        )
    
}

export default Checkout;