import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";








// import { Container, Card, Row, Col } from "react-bootstrap";
import FavoriteIcon from '@material-ui/icons/Favorite';
import logo1 from '../assets/images/logo1.png';

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ReplayIcon from '@material-ui/icons/Replay';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PaymentIcon from '@material-ui/icons/Payment';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import PermIdentitySharpIcon from '@material-ui/icons/PermIdentitySharp';
import RemoveRedEyeSharpIcon from '@material-ui/icons/RemoveRedEyeSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Carousel from 'react-bootstrap/Carousel';


// import car1 from './img/carousel-1.jpg';
// import car2 from './img/carousel-2.jpg';



// import cat1 from './img/cat-1.jpg';
// import cat2 from './img/cat-2.jpg';
// import cat3 from './img/cat-3.jpg';
// import cat4 from './img/cat-4.jpg';
// import cat5 from './img/cat-5.jpg';
// import cat6 from './img/cat-6.jpg';







// import product1 from './img/product-1.jpg';
// import product2 from './img/product-2.jpg';
// import product3 from './img/product-3.jpg';
// import product4 from './img/product-4.jpg';
// import product5 from './img/product-5.jpg';
// import product6 from './img/product-6.jpg';
// import product7 from './img/product-7.jpg';
// import product8 from './img/product-8.jpg';

import vendor1 from './img/vendor-1.jpg';
// import vendor2 from './img/vendor-2.jpg';
// import vendor3 from './img/vendor-3.jpg';
// import vendor4 from './img/vendor-4.jpg';
// import vendor5 from './img/vendor-5.jpg';
// import vendor6 from './img/vendor-6.jpg';
// import vendor7 from './img/vendor-7.jpg';
// import vendor8 from './img/vendor-8.jpg';



import s1 from './img/4.gif';
import s2 from './img/5.gif';







const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [SliderPhoto1 , setSliderPhoto1] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [chk1 , setchk1] = useState(true)
    const [chk2 , setchk2] = useState(true)
    const [Ite1 , setIte1] = useState(1)
    const [Ite2 , setIte2] = useState(1)


    const [SizeWidth , setSizeWidth] = useState(0)

    const [fg , setfg] = useState(true)
    var settings = {
      infinite: true,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      lazyLoad: true,
      autoplay: true,
    autoplaySpeed: 2000,
    };
    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
      setSizeWidth(  window.innerWidth)
      console.log(  
       window.innerWidth, document.documentElement.clientWidth , 
        document.body.clientWidth     )

        fetch("https://halal-dropship-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0]);
            setSliderPhoto(res1)
          })
        fetch("https://halal-dropship-main-back.vercel.app/slider-photo1",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0]);
            setSliderPhoto1(res1)
          })
          // carrouselInit()
        fetch("https://halal-dropship-main-back.vercel.app/companieswith",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res9=>res9.json())
      .then(res11=>{
          console.log(res11[0]);
          setcompanieswith(res11)
      
      })
        fetch("https://halal-dropship-main-back.vercel.app/BannerPhoto",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res22=>res22.json())
        .then(res222=>{
            console.log(res222[0]);
            setBannerPhoto(res222)
        
        })

        fetch("https://halal-dropship-main-back.vercel.app/arrival-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setArrivalPhoto(res3)
        
        })
       

        fetch("https://halal-dropship-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://halal-dropship-main-back.vercel.app/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
        fetch("https://halal-dropship-main-back.vercel.app/FAQ1",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res3=>res3.json())
      .then(res4=>{
        let dat1 = []
        res4.map((res55,c)=>{
            dat1.push(0)
        })
        setfvalue(dat1)
             setdata(res4)
             data.map((res,i)=>{
              data1[i] = res.text
             })

          console.log(res4)
        })
        fetch("https://halal-dropship-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))
    // setproduct1(pro1)
    setdis(true)
   })
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])

 const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
 
 
 const cate =(Categories) =>{
     var Cat1 = Categories.split(" ").join("-")
     
     localStorage.setItem("Cate" , JSON.stringify(Categories) )
     
     setTimeout(()=>{
            props.history.push(`/shop/categories/${Cat1}`)
        },1500)
    }
    
    
//     const addtocartproduct = (data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


//        if(data111.ProductCreater !== "60a3c644e4520a12c80a6f52"){
//         console.log("yes ");
//         fetch("https://halal-dropship-main-back.vercel.app/ChangePercentageInOrder/"+data111.ProductCreater,{
//                             method: "POST",
//                             headers :  {
//                                 "Content-Type" : "application/json" , 
//                             }
//                         })
//                         .then(res=>res.json())
//                         .then((res1)=>{ 
//                             console.log(res1);
//                             // if(!res1 === null){
// document.getElementById("myDi").style.visibility = "visible"
//     setTimeout(()=>{
//         document.getElementById("myDi").style.visibility = "hidden"

//     },1000)
//     if (data111.Product_Price_Discounted === null){
//         const data =  {...data111 ,
                    
//                     Percentage : data111.Percentage,
//                     DeliveryStatus : "Pending",
//                      Pieces :1,
//                      Total_Product_Price  : data111.Product_Price *1}
//     var data1 = JSON.parse(localStorage.getItem("Cart")) 
//     if (data1){
//         var data3 = data1.map((item) => {
//             if(item._id === data111._id){
//                 console.log("double");
// ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//         })
//         var data5 =  JSON.parse(localStorage.getItem("double")) 
//         // console.log(DataPart2.length, data3.length,data5);
//         var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//         if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )
         
//         }
//         else{
//             console.log("Differet");
//             var data2 = [...data1 , data]
        
//        localStorage.setItem("Cart" , JSON.stringify(data2) )
//         }
//     }
//     else{
//         console.log("1");
//         localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//     }

//     }
//     else if (data111.Product_Price_Discounted){
//         const data =  {...data111 ,
                    
//                     Percentage : data111.Percentage,
//                     DeliveryStatus : "Pending",
//                      Pieces :1,
//                      Total_Product_Price  : data111.Product_Price_Discounted *1}
//     var data1 = JSON.parse(localStorage.getItem("Cart")) 
//     if (data1){
//         var data3 = data1.map((item) => {
//             if(item._id === data111._id){
//                 console.log("double");
// ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//         })
//         var data5 =  JSON.parse(localStorage.getItem("double")) 
//         // console.log(DataPart2.length, data3.length,data5);
//         var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//         if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )
         
//         }
//         else{
//             console.log("Differet");
//             var data2 = [...data1 , data]
        
//        localStorage.setItem("Cart" , JSON.stringify(data2) )
//         }
//     }
//     else{
//         console.log("1");
//         localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//     }

//     }
//     else if (data111.Size_Discounted[0]){
//         const data =  {...data111 ,
//             Percentage : data111.Percentage,
//             DeliveryStatus : "Pending",
//             Pieces :1,
//             Size : data111.Size[0],
//             Product_Price_Discounted : data111.Size_Discounted[0],
//             Total_Product_Price  : data111.Size_Discounted[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )
            
//             }
//             else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data]
            
//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )

//             }
//     }


//     else{




//                         const data =  {...data111 ,
//                             Percentage : data111.Percentage,
//                             DeliveryStatus : "Pending",
//                             Pieces :1,
//                             Size : data111.Size[0],
//                             Product_Price_Discounted : data111.Price[0],
//                             Total_Product_Price  : data111.Price[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )
              
//             }
//             else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]
             
//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//             }


//     }


//   })
    

// }













// else{
  
//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 // console.log(productDetail);
//                 if (data111.Product_Price_Discounted === null){
//                 const data =  {...data111 ,
//                     Percentage : 0,
//                     DeliveryStatus : "Pending",
//                     Pieces :1,
//                     Total_Product_Price  : data111.Product_Price *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 console.log(  data1);
//                 if (data1){
//                     console.log(  data111._id);
//                 var data3 = data1.map((item) => {
//                     console.log(item._id , data111._id);
//                     if(item._id === data111._id ){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                 console.log("double not match");
//                 return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }

//                 }
//                 else if (data111.Product_Price_Discounted){
//                 const data =  {...data111 ,
//                     Percentage : 0,
//                     DeliveryStatus : "Pending",
//                     Pieces :1,
//                     Total_Product_Price  : data111.Product_Price_Discounted *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 console.log(  data1);
//                 if (data1){
//                     console.log(  data111._id);
//                 var data3 = data1.map((item) => {
//                     console.log(item._id , data111._id);
//                     if(item._id === data111._id ){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                 console.log("double not match");
//                 return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }

//                 }
//                 else if (data111.Size_Discounted[0]){
//                     const data =  {...data111 ,
//                         Percentage : 0,
//                         DeliveryStatus : "Pending",
//                         Pieces :1,
//                         Size : data111.Size[0],
//                         Product_Price_Discounted : data111.Size_Discounted[0],
//                         Total_Product_Price  : data111.Size_Discounted[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )

//             }
//             else{
//             console.log("Differet");
//             var data2 = [...data1 , data111]

//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )

//             }

//                 }
//                 else{




//                         const data =  {...data111 ,
//                             Percentage : 0,
//                             DeliveryStatus : "Pending",
//                             Pieces :1,
//                             Size : data111.Size[0],
//                             Product_Price_Discounted : data111.Price[0],
//                             Total_Product_Price  : data111.Price[0] *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 if (data1){
//                 var data3 = data1.map((item) => {
//                     if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }


//                 }


//                     }






































//     }

    
const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}
    const carrouselInit =() => {

      var carrousel = document.getElementById("carrouselimg1");
      carrousel.style.opacity = "1";
      setInterval(carrouselAutoChange, 3500);
    
    }
    
    const carrouselAutoChange=()  =>{
      carrouselRight();
    }
    
    
    
    const carrouselChange=(i) => {
    
      var carrousel = document.getElementById("carrouselimg" + i);
      carrousel.style.opacity = "1";
    
    }
    
    
    const carrouselLeft=()  =>{
    
      let nbCarrousel = 4;
      let num = 0;
    
      for (let i = 0; i < nbCarrousel; i++) {
          num = i + 1;
          var carrousel = document.getElementById("carrouselimg" + num);
          if (carrousel.style.opacity == "1") {
              carrousel.style.opacity = "0";
              if (i == 0) {
                  return carrouselChange(4);
              }
              return carrouselChange(num - 1);
          }
      }
    }
    
    const carrouselRight=() =>{
    
      let nbCarrousel = 4;
      let num = 0;
    
      for (let i = 0; i < nbCarrousel; i++) {
          num = i + 1;
          var carrousel = document.getElementById("carrouselimg" + num);
          if (carrousel.style.opacity == "1") {
              carrousel.style.opacity = "0";
              if (i == 3) {
                  return carrouselChange(1);
              }
              return carrouselChange(num + 1);
          }
      }
    }
    
    
    // const addtocartproduct = (productDetail) =>{
    //   swal("Update!", "Your Item Add in Cart!", "success");
    
    //   // if(productDetail){
    //       // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          
    
    
        
    //                   if (productDetail[0].Product_Price_Discounted === null){
    //                   const data =  {...productDetail[0] ,
    //                       Percentage : 0,
    //                       DeliveryStatus : "Pending",
    //                       Pieces :1,
    //                       Total_Product_Price  : productDetail[0].Product_Price *1}
    //                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
    //                   if (data1){
    //                   var data3 = data1.map((item) => {
    //                       if(item._id === data._id ){
    //                           console.log("double");
    //                   ;                   localStorage.setItem("double",JSON.stringify(true))
    //                   return {...item,
    //                       Pieces :1+ item.Pieces,
    //                       Total_Product_Price  : (productDetail[0].Product_Price *1)+ item.Total_Product_Price}
    //                   }
    //                   else{
    //                   console.log("double not match");
    //                   return item
    //                   }
    
    //                   })
    //                   var data5 =  JSON.parse(localStorage.getItem("double")) 
    //                   console.log(DataPart2.length, data3.length,data5);
    //                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
    //                   if(data10.length=== data3.length && data5){
    //                   console.log("double remove");
    //                   localStorage.removeItem("double")
    //                   localStorage.setItem("Cart" , JSON.stringify(data3) )
    
    //                   }
    //                   else{
    //                   console.log("Differet");
    //                   var data2 = [...data1 , data]
    
    //                   localStorage.setItem("Cart" , JSON.stringify(data2) )
    //                   }
    //                   }
    //                   else{
    //                   console.log("1");
    //                   localStorage.setItem("Cart" , JSON.stringify([data]) )
    
    //                   }
    
    //                   }
    //                   else if (productDetail[0].Product_Price_Discounted){
    //                   const data =  {...productDetail[0] ,
    //                       Percentage : 0,
    //                       DeliveryStatus : "Pending",
    //                       Pieces :1,
    //                       Total_Product_Price  : productDetail[0].Product_Price_Discounted *1}
    //                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
    //                   if (data1){
    //                   var data3 = data1.map((item) => {
    //                       if(item._id === data._id ){
    //                           console.log("double");
    //                   ;                   localStorage.setItem("double",JSON.stringify(true))
    //                   return {...item,
    //                       Pieces :1+ item.Pieces,
    //                       Total_Product_Price  : (productDetail[0].Product_Price_Discounted *1)+ item.Total_Product_Price}
    //                   }
    //                   else{
    //                   console.log("double not match");
    //                   return item
    //                   }
    
    //                   })
    //                   var data5 =  JSON.parse(localStorage.getItem("double")) 
    //                   console.log(DataPart2.length, data3.length,data5);
    //                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
    //                   if(data10.length=== data3.length && data5){
    //                   console.log("double remove");
    //                   localStorage.removeItem("double")
    //                   localStorage.setItem("Cart" , JSON.stringify(data3) )
    
    //                   }
    //                   else{
    //                   console.log("Differet");
    //                   var data2 = [...data1 , data]
    
    //                   localStorage.setItem("Cart" , JSON.stringify(data2) )
    //                   }
    //                   }
    //                   else{
    //                   console.log("1");
    //                   localStorage.setItem("Cart" , JSON.stringify([data]) )
    
    //                   }
    
    //                   }
    
    
                          
    
    // }
    
    


const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}

const addtocartproduct8 = (productDetail) =>{
  swal("Update!", "Item Save!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }


  }


      

}

    
    const addtocartproduct12 = (productDetail) =>{
  
      if (productDetail[0].Product_Price_Discounted === null){
        const data =  {...productDetail[0] ,
            Percentage : 0,
            DeliveryStatus : "Pending",
            Pieces : 1 ,
            Total_Product_Price  : productDetail[0].Product_Price * 1 }
        var data1 = JSON.parse(localStorage.getItem("Cart")) 
        if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data._id ){
                console.log("double");
        ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
        }
        else{
        console.log("double not match");
        return item
        }
    
        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
        if(data10.length=== data3.length && data5){
        console.log("double remove");
        localStorage.removeItem("double")
        localStorage.setItem("Cart" , JSON.stringify(data3) )
    
        }
        else{
        console.log("Differet");
        var data2 = [...data1 , data]
    
        localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
        }
        else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
    
        }
    
        }
        else if (productDetail[0].Product_Price_Discounted){
        const data =  {...productDetail[0] ,
            Percentage : 0,
            DeliveryStatus : "Pending",
            Pieces : 1 ,
            Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
        var data1 = JSON.parse(localStorage.getItem("Cart")) 
        if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data._id ){
                console.log("double");
        ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
        }
        else{
        console.log("double not match");
        return item
        }
    
        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
        if(data10.length=== data3.length && data5){
        console.log("double remove");
        localStorage.removeItem("double")
        localStorage.setItem("Cart" , JSON.stringify(data3) )
    
        }
        else{
        console.log("Differet");
        var data2 = [...data1 , data]
    
        localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
        }
        else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
    
        }
    
        }
    
    
      props.history.push("/custom-product/photo-upload")
    }
    

    const setChange6 = (i) =>{
      console.log(i)
       if(      document.getElementById("catego"+i).style.display  ==="block")
      document.getElementById("catego"+i).style.display  ="none"
      else
      document.getElementById("catego"+i).style.display  ="block"
    }

    const setChan = (i) =>{
      console.log(i)
       if(      document.getElementById("categ"+i).style.display  ==="block")
      document.getElementById("categ"+i).style.display  ="none"
      else
      document.getElementById("categ"+i).style.display  ="block"
    }
    const setChan1 = (i,data) =>{
    //  let  e =  []
    //   for (let s = 0; s < SubCategories.length; s++) {
    //     if(data === SubCategories[s].MainCategories)
    //     e.push( SubCategories[s])
    //   }
    //   console.log(i,e)
    //   // console.log(a)
    //   for (let a = 0; a < e.length; a++) {
    //     console.log(a)
    //      document.getElementById("cate"+a).style.display  ="none"
 
    //    }
    console.log(document.getElementById("cate"+i),i)
       if(      document.getElementById("cate"+i).style.display  ==="block"){
        console.log("yes")
        document.getElementById("cate"+i).style.display  ="none"
      }
      else{
        console.log("yes1")

        document.getElementById("cate"+i).style.display  ="block"
      }
          let a =  []
          SubCategories.map((res1,j)=>{
            if(data === res1.MainCategories){
              a.push(j)
            }
          })
          console.log(a)
      if(a[0] !== i){
          document.getElementById("cate"+a[0]).style.display  ="none"
        }
      // if(i !== 0){
    }
    const setChan2 = (i) =>{
      console.log(i)
       if(      document.getElementById("categp"+i).style.display  ==="block")
      document.getElementById("categp"+i).style.display  ="none"
      else
      document.getElementById("categp"+i).style.display  ="block"
    }
    const setChan3 = (i,data) =>{
      // for (let a = 0; a < SubCategories.length; a++) {
      //  console.log(a)
      //   document.getElementById("catep"+a).style.display  ="none"

      // }
      console.log(i)
       if(      document.getElementById("catep"+i).style.display  ==="block")
      document.getElementById("catep"+i).style.display  ="none"
      else
      document.getElementById("catep"+i).style.display  ="block"

      let a =  []
      SubCategories.map((res1,j)=>{
        if(data === res1.MainCategories){
          a.push(j)
        }
      })
      console.log(a)
  if(a[0] !== i){
      document.getElementById("catep"+a[0]).style.display  ="none"
    }
    }
    const setChang677 = () =>{
      console.log( document.getElementById("navbar-vertical1"))
      if( document.getElementById("navbar-vertical1").style.display === "none")
      document.getElementById("navbar-vertical1").style.display = "block"
      else
      document.getElementById("navbar-vertical1").style.display = "none"
    }
    // const setChange9 = () =>{
    //   product8.map((res,i)=>{
    //     if(Ite1*8  >(Ite1+1 )*8){
          
    //     }
    //   })
    //   setIte1(Ite1 + 1)
    // }
    const setChange9 = () =>{
      setproduct(product8)
      setchk1(false)
     }
     const setChange10 = () =>{
       setproduct1(product9)
       setchk2(false)
     }
        return (
            
            <div>
        
     

     
        <div className="container-fluid">
          <div className="row px-xl-5">
            <div className="col-lg-3 d-none d-lg-block"  style={{paddingRight : "0px"}}>
              <a className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100" data-toggle="collapse" href="#navbar-vertical" aria-expanded="false" style={{height: '37px', marginTop: '-1px', padding: '0 30px'}} onClick={()=>setChang677()}>
                <h6 className="m-0" style={{padding:"0px"}}>Categories</h6>
                <KeyboardArrowDownIcon />
              </a>
              <nav className="collapse show navbar navbar-vertical navbar-light align-items-start p-0 border-top-0 border-bottom-0" id="navbar-vertical1">
                
                
                
                
                
                
                
                
                <div className="navbar-nav w-100" style={{height: '410px',overflow:"scroll"}}>
 







                  {MainCatogories.map((res,i)=>{
                    return (
                      <>
                      
                     
                      <a href className="nav-item nav-link" ><Link  to={"/categories/"+res.MainCategories+"/all-products"}>{res.MainCategories} </Link>
                       {Cate1[i] === "1" ?
<KeyboardArrowDownIcon onClick={()=>setChan(i)}/>
: ""
                       }
                       
                       
                       </a>
                     
                     <div className="nav-item dropdown" style={{display : "none"}} id={"categ"+i}>
                      {  SubCategories.map((res1,j)=>{
                        // console.log(res1.MainCategories , res.MainCategories)
                        if(res1.MainCategories === res.MainCategories){
                          // console.log(j,res1.CategoriesName)
                          return (
                            <>
                            <a className="nav-link" data-toggle="dropdown" style={{padding  :"5px 30px"}}> <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/all-products"}> {res1.CategoriesName}</Link>
                           
{Cate2[j] === "1" ?
                            <KeyboardArrowDownIcon  onClick={()=>setChan1(j,res.MainCategories)}/>
: ""
                       }
                             </a>
                        <div className="dropdown-menu rounded-0 w-100 m-0" id={"cate"+j}>
                        {SubClassCategories.map((res2,k)=>{
                        // console.log(res2)
                        if(res2.MainCategories === res.MainCategories && res2.CategoriesName === res1.CategoriesName){
                          return (
                            <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res2.Second_Categories+"/all-products"} className="dropdown-item" style={{padding  :"5px 40px"}}>{res2.Second_Categories}</Link>


                          )
                        } 
                      })
                    }
                       
                        {/* <a href className="dropdown-item">Men's Dresses</a>
                        <a href className="dropdown-item">Women's Dresses</a>
                        <a href className="dropdown-item">Baby's Dresses</a> */}
                      </div>
                            </>
                    
                          )
                        }
                        })
                      }
                      </div>
                     
                      </>
                    )
                  })

                  }
               















                </div>
























              </nav>
            </div>  
            {SizeWidth >529 ? 
             <div className="col-lg-9 change-mobile-2" style={{padding : "0px"}}>
             
             <div id="header-carousel" className="carousel slide" data-ride="carousel">
               <div className="carousel-inner">
               {SliderPhoto[0] &&SliderPhoto[0].photo1 !== null ? 

                 <div className="carousel-item active" style={{height: '410px'}}>
                   <img className="img-fluid" src={SliderPhoto[0].photo1 &&SliderPhoto[0].photo1} alt="Image"  style={{objectFit :"fill" }}/>
                
                 </div>
                     :""
                   }
                 {SliderPhoto[0] &&SliderPhoto[0].photo2 !== null ? 

                 <div className="carousel-item" style={{height: '410px'}}>
                   <img className="img-fluid" src={SliderPhoto[0].photo2} alt="Image"  style={{objectFit :"fill" }}/>
                
                 </div>
                   :""
                                   }
                 {SliderPhoto[0] &&SliderPhoto[0].photo3 !== null? 
                 <div className="carousel-item" style={{height: '410px'}}>
                   <img className="img-fluid" src={SliderPhoto[0].photo3} alt="Image"  style={{objectFit :"fill" }}/>
                 
                 </div>
                 :""
                                 }
                 {SliderPhoto[0] &&SliderPhoto[0].photo4!== null ? 
                 <div className="carousel-item" style={{height: '410px'}}>
                   <img className="img-fluid" src={SliderPhoto[0].photo4} alt="Image"  style={{objectFit :"fill" }}/>
          
                 </div>
                 :""
                 }
               </div>
               <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
                 <div className="btn btn-dark" style={{width: '45px', height: '45px'}}>
                   <span className="carousel-control-prev-icon mb-n2" />
                 </div>
               </a>
               <a className="carousel-control-next" href="#header-carousel" data-slide="next">
                 <div className="btn btn-dark" style={{width: '45px', height: '45px'}}>
                   <span className="carousel-control-next-icon mb-n2" />
                 </div>
               </a>
             </div>
           </div>
 :
 <div className="col-lg-9 change-mobile-1" style={{padding : "0px"}}>
             
              <div id="header-carousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                {SliderPhoto1[0] &&SliderPhoto1[0].photo1 !== null ? 

                  <div className="carousel-item active" style={{height: '410px'}}>
                    <img className="img-fluid" src={SliderPhoto1[0].photo1 &&SliderPhoto1[0].photo1} alt="Image"  style={{objectFit :"fill" }}/>
                 
                  </div>
                      :""
                    }
                  {SliderPhoto1[0] &&SliderPhoto1[0].photo2 !== null ? 

                  <div className="carousel-item" style={{height: '410px'}}>
                    <img className="img-fluid" src={SliderPhoto1[0].photo2} alt="Image"  style={{objectFit :"fill" }}/>
                    
                  </div>
                    :""
                                    }
                  {SliderPhoto1[0] &&SliderPhoto1[0].photo3 !== null? 
                  <div className="carousel-item" style={{height: '410px'}}>
                    <img className="img-fluid" src={SliderPhoto1[0].photo3} alt="Image"  style={{objectFit :"fill" }}/>
                  
                  </div>
                  :""
                                  }
                  {SliderPhoto1[0] &&SliderPhoto1[0].photo4!== null ? 
                  <div className="carousel-item" style={{height: '410px'}}>
                    <img className="img-fluid" src={SliderPhoto1[0].photo4} alt="Image"  style={{objectFit :"fill" }}/>
           
                  </div>
                  :""
                  }
                </div>
                <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
                  <div className="btn btn-dark" style={{width: '45px', height: '45px'}}>
                    <span className="carousel-control-prev-icon mb-n2" />
                  </div>
                </a>
                <a className="carousel-control-next" href="#header-carousel" data-slide="next">
                  <div className="btn btn-dark" style={{width: '45px', height: '45px'}}>
                    <span className="carousel-control-next-icon mb-n2" />
                  </div>
                </a>
              </div>
            </div>

           
            }
           





          








          </div>
        </div>
        {/* Navbar End */}
        {/* Featured Start */}





        {/* <div className="container-fluid pt-5">
          <div className="row px-xl-5 pb-3">
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
              <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
                <h1>
                  <CheckIcon style={{color : "rgb(145,178,146)",fontSize :"50px"}}/>
                  </h1>
                <h5 className="font-weight-semi-bold m-0">Quality Product</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
              <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
              <h1>
                  <LocalShippingIcon  style={{color : "rgb(145,178,146)",fontSize :"50px"}}/>
                  </h1>                <h5 className="font-weight-semi-bold m-0">Free Shipping</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
              <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
              
              <h1>
                  <KeyboardReturnIcon  style={{color : "rgb(145,178,146)",fontSize :"50px"}}/>
                  </h1>                   <h5 className="font-weight-semi-bold m-0">14-Day Return</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
              <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
              <h1>
                  <PermIdentitySharpIcon  style={{color : "rgb(145,178,146)",fontSize :"50px"}}/>
                  </h1>   
                <h5 className="font-weight-semi-bold m-0">24/7 Support</h5>
              </div>
            </div>
          </div>
        </div> */}



        {/* Featured End */}

         {/* Products Start */}
         <div className="container-fluid pt-5">
          <div className="text-center mb-4">
            <h2 className="section-title px-5"><span className="px-2">Best Selling Products</span></h2>
          </div>
          <div className="row px-xl-5 pb-3">
          {product.map((res,i)=>{
            if(res.Course_Sub_Catagories !== ""){
                return(
<div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
              <div className="card product-item border-0 mb-4">
                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/>
                </Link></div>
                <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                  <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                  <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                  {res.Product_Price_Discounted ? 
<><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
:
<h6>Rs {(res.Product_Price).toFixed(0)}</h6>

                  }                  </div>
                </div></Link>
                <div className="card-footer d-flex justify-content-between bg-light border">
                  <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                  <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                </div>
              </div>
            </div>

                )
            }
            if(res.Product_Sub_Catagories !== ""){
              console.log("323")
              return(
                <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                              <div className="card product-item border-0 mb-4">
                                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/></Link>
                                </div>
                                <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                  <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                                  <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                                  {res.Product_Price_Discounted ? 
                <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
                :
                <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
                
                                  }                  </div>
                                </div></Link>
                                <div className="card-footer d-flex justify-content-between bg-light border">
                                  <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                                  <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                                </div>
                              </div>
                            </div>
                
                                )
            }
            else{
              return(
                <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                              <div className="card product-item border-0 mb-4">
                                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                  <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/></Link>
                                </div>
                                <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                  <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                                  <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                                  {res.Product_Price_Discounted ? 
                <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
                :
                <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
                
                                  }                  </div>
                                </div></Link>
                                <div className="card-footer d-flex justify-content-between bg-light border">
                                  <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                                  <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                                </div>
                              </div>
                            </div>
                
                                )
            }
              
              })
                
              }
          
            
           
            
          </div>
          {chk1 ?

<center>
  <h3 onClick={()=>setChange9()} style={{color : "#285a73",textDecoration : "underline"}}>See more</h3>
</center>
:""
}
          {/* <center>
            <h3 onClick={()=>setChange9()}>See more</h3>
          </center> */}
        </div>
        {/* Products End */}
        {/* Categories Start */}
        <div className="container-fluid">
        <div className="text-center mb-4">
            <h2 className="section-title px-5"><span className="px-2">Categories</span></h2>
          </div>
          <div className="row px-xl-5 pb-3">
            {MainCatogories.map((res,i)=>{
              return (
                <div className="col-lg-3 col-md-6 pb-1" onClick={()=>props.history.push("/categories/"+res.MainCategories+"/all-products")} style={{cursor :"pointer"}}>
                  <div className="cat-item d-flex flex-column border mb-4" style={{padding: '14px'}}>
                    {/* <p className="text-right">15 Products</p> */}
                    <a href className="cat-img position-relative overflow-hidden mb-3">
                      <img className="img-fluid image-size-change" src={res.photo} alt="" style={{maxHeight:"430px"}}/>
                    </a>
                    <h5 className="font-weight-semi-bold m-0" style={{fontWeight : "bold"}}>{res.MainCategories}</h5>
                  </div>
                </div>
              )
            })

            }
            
          </div>
        </div>
        {/* Categories End */}
        {/* Offer Start */}
        {/* <div className="container-fluid offer pt-5">
          <div className="row px-xl-5">
            <div className="col-md-6 pb-4">
              <div className="position-relative text-center text-md-right text-white mb-2 py-5 px-5" style={{border: "1px solid black"}}>
                <img src={s2} alt="" />
                <div className="position-relative" style={{zIndex: 1}}>
                  <h5 className="text-uppercase text-primary mb-3">UPTO 50% off </h5>
                  <h1 className="mb-4 font-weight-semi-bold">Home & Lifestyle</h1>
                  <a href className="btn btn-outline-primary py-md-2 px-md-3" style={{color : "#D19C97" }}>Shop Now</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 pb-4">
              <div className="position-relative text-center text-md-left text-white mb-2 py-5 px-5" style={{border: "1px solid black"}}>
                <img src={s1} alt="" />
                <div className="position-relative" style={{zIndex: 1}}>
                  <h5 className="text-uppercase text-primary mb-3">UPTO 50% off</h5>
                  <h1 className="mb-4 font-weight-semi-bold">Kitchen Accesories</h1>
                  <a href className="btn btn-outline-primary py-md-2 px-md-3" style={{color : "#D19C97" }}>Shop Now</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Offer End */}
        {/* Products Start */}
        <div className="container-fluid pt-5">
          <div className="text-center mb-4">
            <h2 className="section-title px-5"><span className="px-2">Trendy  Products</span></h2>
          </div>
          <div className="row px-xl-5 pb-3">
          {product1.map((res,i)=>{
                if(res.Course_Sub_Catagories !== ""){
                  return(
  <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                <div className="card product-item border-0 mb-4">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/>
                  </Link></div>
                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                    <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                    {res.Product_Price_Discounted ? 
  <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
  :
  <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
  
                    }                  </div>
                  </div></Link>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                    <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                  </div>
                </div>
              </div>
  
                  )
              }
              if(res.Product_Sub_Catagories !== ""){
                console.log("323")
                return(
                  <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                                <div className="card product-item border-0 mb-4">
                                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/></Link>
                                  </div>
                                  <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                    <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                                    <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                                    {res.Product_Price_Discounted ? 
                  <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
                  :
                  <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
                  
                                    }                  </div>
                                  </div></Link>
                                  <div className="card-footer d-flex justify-content-between bg-light border">
                                    <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                                    <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                                  </div>
                                </div>
                              </div>
                  
                                  )
              }
              else{
                return(
                  <div className="col-lg-3 col-md-6 col-sm-12 pb-1" >
                                <div className="card product-item border-0 mb-4">
                                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                    <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><img className="img-fluid w-100" src={res.Product_Image_Upload} alt=""  onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}/></Link>
                                  </div>
                                  <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}><div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                    <h6 className="text-truncate mb-3" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer",width: "250px",margin : "auto"}}>{res.Product_Name}</h6>
                                    <div className="d-flex justify-content-center" onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)} style={{cursor : "pointer"}}>
                                    {res.Product_Price_Discounted ? 
                  <><h6>Rs {(res.Product_Price_Discounted).toFixed(0)}</h6><h6 className="text-muted ml-2" style={{padding :"0px"}}><del>Rs {(res.Product_Price).toFixed(0)}</del></h6></>
                  :
                  <h6>Rs {(res.Product_Price).toFixed(0)}</h6>
                  
                                    }                  </div>
                                  </div></Link>
                                  <div className="card-footer d-flex justify-content-between bg-light border">
                                    <a href className="btn btn-sm p-0" onClick={()=>addtocartproduct8([res])}><FavoriteIcon/>Save</a>
                                    <a href className="btn btn-sm p-0 hover-effect-1" onClick={()=>addtocartproduct([res])}><ShoppingCartIcon />Add To Cart</a>
                                  </div>
                                </div>
                              </div>
                  
                                  )
              }
              })
                
              }
          
          </div>
          {chk2 ?
          <center>
            <h3 onClick={()=>setChange10()} style={{color : "#285a73",textDecoration : "underline"}}>See more</h3>
          </center>
  :""
          }
        </div>
        {/* Products End */}
        {/* Subscribe Start */}
        {/* <div className="container-fluid bg-secondary my-5">
          <div className="row justify-content-md-center py-5 px-xl-5">
            <div className="col-md-6 col-12 py-5">
              <div className="text-center mb-2 pb-2">
                <h2 className="section-title px-5 mb-3"><span className="bg-secondary px-2">Stay Updated</span></h2>
                <p>Amet lorem at rebum amet dolores. Elitr lorem dolor sed amet diam labore at justo ipsum eirmod duo labore labore.</p>
              </div>
              <form action>
                <div className="input-group">
                  <input type="text" className="form-control border-white p-4" placeholder="Email Goes Here" />
                  <div className="input-group-append">
                    <button className="btn btn-primary px-4">Subscribe</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
        {/* Subscribe End */}
       







{/* <video controls src="https://hhcdropshipping.com/Member/wp-content/uploads/2022/04/Coffee-Cup-Warmer-Heater-Coaster-Desktop-220v-Electric-Mug-Warmer-Heater-Set-1.mp4?_=2"></video> */}









        {/* Vendor Start */}
        {/* <div className="container-fluid py-5">
          <div className="row px-xl-5">
            <div className="col">
              <div className="owl-carousel vendor-carousel">
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
                <div className="vendor-item border p-4">
                  <img src={vendor1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Vendor End */}
        {/* Footer Start */}
        
        {/* Footer End */}
        {/* Back to Top */}
        <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up" /></a>
      </div>
        )
    
}

export default Home;