import React,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'


const Signup = (props) => {
    const [pass ,  setpass ] = useState("")
    const [phone ,  setphone ] = useState("+92")





    useEffect(() => {
      if(JSON.parse(localStorage.getItem("user main"))){
        props.history.push("/home1")
        
    }
    else{
        

    }
           }, [])
  

const postData = (e) =>{
    e.preventDefault()
   
    if(pass.length === 0){
        swal("Enter The Password !"  )
           return
         }
    if(phone.length === 0){
        swal("Enter The Phone Number !"  )
           return
         }
         if(phone.length !== 13){
          swal("Enter The Phone Number !"  )
             return
           }
           console.log(phone,pass)
      fetch("https://halal-dropship-main-back.vercel.app/main-login-user",{
        method: 'POST' , 
        headers :  {
          "Content-Type" : "application/json" , 
        } , 
        body : JSON.stringify({
          pass  ,
          phone ,
        })
      })
      .then((res)=>res.json())
      .then((res2)  =>{
          console.log(res2)
          if (res2 !== null && ! res2.Error  ){
            // swal("SucessFully Login"  )
            localStorage.setItem("user main" , JSON.stringify(res2) )
            // document.getElementById("myDiv1234").style.display = "none"

                props.history.push("/home1")
          }
          else{
            swal("Email & Password are Incorrect Plz Try Again !"  )
          }
        // console.log(res2)
      })
      .catch((err)=>console.log(err))
    }












  return (
       <div className="container-fuild rgjrgou1" style={{width : "30%" , margin : "150px auto",padding: "40px 41px",borderRadius:"6px",boxShadow: "0px 2px 6px black"}}>
            <form  onSubmit={(e)=>postData(e)}>
                {/* <center> */}

                <div style={{width: "90%",margin: "auto"}}>
                  <center>
                     <h2 style={{color : "rgb(61, 61, 61)",fontWeight :"bold"}}>Welcome Back</h2>
                    </center> 
                  <center>
                    <h6 style={{color : "rgb(122, 122, 122)"}}>Don’t have an Account?  <span style={{textDecoration: "underline",color : "grey"}}> <Link to="/user/signup" style={{color : "grey"}}> Signup Now</Link></span></h6>
                    </center> 
                </div>
                <br />
                {/* </center> */}

                <div className="form-group" style={{width: "90%",margin: "auto",padding:"0px 0px 20px 0px"}}>
                    <label style={{fontWeight :"bold" , color : "black"}}>Phone</label>
                    <input type="text"  value={phone} onChange={(e)=>setphone(e.target.value)}  className="form-control" placeholder="Enter phone" />
                </div>
               
                
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black"}}>Password</label>
                    <input type="password"   value={pass} onChange={(e)=>setpass(e.target.value)}  className="form-control" placeholder="Enter password" />
                </div>
            <br/>

                <button type="submit" className="btn btn-primary btn-block" style={{width: "90%",margin: "0px auto 10px auto",background : "grey" , border : "1px solid grey",fontWeight : "bold"}}>Sign In</button>
                {/* <p className="forgot-password text-right">
                    Forgot <Link to="/forget-pass-admin">password?</Link>
                </p> */}
                {/* <p className=" text-center">
                    <Link to="/login">Already Have an Account </Link>

                </p> */}
            </form>
            <center>
                    <span style={{textDecoration: "underline"}}> <Link to="/user/forget-password-account-recovery" style={{margin:"20px 0px 20px 0px"}}>Forgot Password </Link> </span>
            </center>
        </div>
  )
}
export default Signup