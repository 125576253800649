import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

import demo from './Pages/demo';
import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

import Signup from './Pages/Admin/Signup';
import EditCard from "./Pages/EditCart"
import CategoriesBook from './Pages/CategoriesBook';
import CategoriesBags from './Pages/CategoriesBags';
import CategoriesShoes from './Pages/CategoriesShoes';
import ShoesProduct from './Pages/ShoesProduct';
import ShoesProductDetails from './Pages/ShoesProductDetails';
import SchoolClassProduct from './Pages/SchoolClassProduct';
import BagsClassProduct from './Pages/BagsClassProduct';
import chat from './Pages/chat';
import SignupOtp from './Pages/SignupOtp';


import whatapps from './assets/images/what.png';






import SubCategories from './Pages/Categories';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';












import "./bootstrap.min.css"
import "./style1.css"
import "./style11.css"
import "./style111.css"
import "./css/custom.css";
import "./css/responsive.css";
import "./css/bootstrap-select.css";
import "./css/baguetteBox.min.css";
// import "./css/owl.carousel.min.css";
import "./css/bootsnav.css";
import "./css/code_animate.css";
import "./css/carousel-ticker.css";
import "./css/superslides.css";
// import "./css/all.css";
import "./css/style.css";
import CategoriesUniform from './Pages/CategoriesUniform';
import UniformClassProduct from './Pages/UniformClassProduct';
import UniformProductDetails from './Pages/UniformProductDetails';
import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
import BagsProductDetail from './Pages/BagsProductDetail';
import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';



import teacherform from './Pages/teacherform';
import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Privacy from './Pages/Privacy';
import MainUserSignup from './Pages/MainUserSignup';
import MainUserLogin from './Pages/MainUserLogin';
import UserAllPostAd from './Pages/UserAllPostAd';
import UserAllSendRequestAd from './Pages/UserAllSendRequestAd';
import UserAllGetRequestAd from './Pages/UserAllGetRequestAd';
import forgetpassaccount from './Pages/forgetpassaccount';


import allChat from './Pages/allChat';
import PostYourAd from './Pages/PostYourAd';
import help from './Pages/help';
import userDashboard from './Pages/userDashboard';
import EditNameAddress from './Pages/EditNameAddress';
import userDashOrderDetails from './Pages/userDashOrderDetails';

// import SubCategoriesProduct from './Pages/SubCategoriesProduct';
// import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
// import SizeProductDetail from './Pages/SizeProductDetail';
// import thankyou from './Pages/Thankyou';
// import chat from './Pages/chat';

import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';


import Home1 from './Pages/Home1';




function App() {
  const whatappsClick = ()=>{
    console.log("efef");
  }
  return (
    <div className="App">
      <Router>

        <Header />
        <div className="desktop-nav" id="desktop-nav1">
            <ul className="desktop-nav-drop">
                       <li>
                            <Link to="/shop"  className="mobile-nav-border">Shoes</Link>
                        </li>
                       <li> 
                            <Link  className="mobile-nav-border">Shoes</Link>    
                        </li>
                       <li>
                            <Link  className="mobile-nav-border">Shoes</Link>
                        </li>
           </ul>
       </div>
              <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" onClick={()=>whatappsClick()} style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/home1' component={Home1} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/post-your-ad' component={PostYourAd} />
          <Route exact path='/user/all-chats' component={allChat} />
          <Route exact path='/user/forget-password-account-recovery' component={forgetpassaccount} />
          <Route exact path='/user/login' component={MainUserLogin} />
          <Route exact path='/user/signup-otp' component={SignupOtp} />
          <Route exact path='/user/signup' component={MainUserSignup} />
          <Route exact path='/user/dashboard' component={userDashboard} />
          <Route exact path='/user/dashboard/edit' component={EditNameAddress} />
          <Route exact path='/user/dashboard/order-detail' component={userDashOrderDetails} />
          <Route exact path='/signup-user' component={Signup} />
          <Route exact path='/abc' component={demo} />
          <Route exact path='/about' component={About} />
          <Route exact path='/shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/help' component={help} />
          <Route exact path='/favorite' component={Favorite} />

          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/shipping-policy' component={Shipping} />
          
          <Route exact path='/student-tutor-forum' component={studentform} />
          <Route exact path='/teacher-medium-forum' component={teacherform} />
          {/* <Route exact path='/shop/categories/:productcategories/:productname' component={Detail} />
          <Route exact path='/shop/categories/:categories' component={CategoriesShop} /> */}


          {/* <Route exact path='/categories/:categoriesName' component={SubCategories} />
          <Route exact path='/categories/:categoriesName/:subcategories' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:subcategories/:productid/:productname' component={SubCategoriesProductDetails} />

          <Route exact path='/main-categories/:SchoolCoursecategoriesName' component={SchoolCategories} />
          <Route exact path='/main-categories/:SchoolCoursecategoriesName/:Classsessubcategories' component={SchoolClassesCategories} />
          <Route exact path='/main-categories/:SchoolCoursecategoriesName/:Classsessubcategories/:ClasssessubcategoriesProduct' component={SchoolClassesCategoriesProduct} />
          <Route exact path='/main-categories/:SchoolCoursecategoriesName/:Classsessubcategories/:ClasssessubcategoriesProduct/:productid/:productname' component={SchoolClassesCategoriesProductDetail} />
          
          
          <Route exact path='/main-categories/:SchoolCoursecategoriesName/:Classsessubcategories/:ClasssessubcategoriesProduct/Use-course' component={SchoolClassCategoriesUseCourseProduct} />
          <Route exact path='/main-categories/:SchoolCoursecategoriesName/:Classsessubcategories/:ClasssessubcategoriesProduct/:productid/Use-course/1' component={SchoolClassCategoriesUseCourseProductDetail} /> */}

          {/* <Route exact path='/categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />


          <Route exact path='/arrival/product' component={ArrivalCategoriesProduct} />
{/* 
          <Route exact path='/categories/bags' component={CategoriesBags} />
          <Route exact path='/categories/bags/product' component={BagsClassProduct} />
          <Route exact path='/categories/bags/product/:productId' component={BagsProductDetail} />





          <Route exact path='/categories/books' component={CategoriesBook} />
          <Route exact path='/categories/books/:schoolName' component={SchoolClassProduct} />
          <Route exact path='/categories/books/:schoolName/:bookProduct' component={SchoolBookProductDetail} />


          <Route exact path='/categories/uniform' component={CategoriesUniform} />
          <Route exact path='/categories/uniform/:uniforms' component={UniformClassProduct} />
          <Route exact path='/categories/uniform/:uniforms/:productname' component={UniformProductDetails} />


          <Route exact path='/categories/shoes' component={CategoriesShoes} />
          <Route exact path='/categories/shoes/products' component={ShoesProduct} />
          <Route exact path='/categories/shoes/products/:productname' component={ShoesProductDetails} />

 */}



          <Route exact path='/shop/search/product-item' component={SearchData} />
          {/* <Route exact path='/detail' component={Detail} /> */}
          <Route exact path='/cart' component={Card} />
          <Route exact path='/edit-card/:productId/:Num' component={EditCard} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/thankyou' component={Thankyou} />
          <Route exact path='/user/all-post-ad' component={UserAllPostAd} />
          <Route exact path='/user/all-send-request-ad' component={UserAllSendRequestAd} />
          <Route exact path='/user/all-get-request-ad' component={UserAllSendRequestAd} />
        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
