import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBA6AtVUXHpDlqk-q-ECNT4ZoQYtiybZPI",
  authDomain: "halal-11b49.firebaseapp.com",
  projectId: "halal-11b49",
  storageBucket: "halal-11b49.appspot.com",
  messagingSenderId: "914616263191",
  appId: "1:914616263191:web:2f31efb8eee71488d57252",
  measurementId: "G-KVLE351D9Q"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export default initializeApp