import React, { useEffect , useState } from 'react';
import swal from 'sweetalert';

import { Link , Redirect} from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import men from '../assets/images/111.png';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import logo1 from '../assets/images/logo1.png';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// import swal from 'sweetalert';
import Swal from 'sweetalert2'



const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};


export function SubCategoriesProductDetails(props) {
  const [productDetail , setproductDetail] = useState([])
  const [Price , setPrice] = useState(1)
  const [DataPart2 , setDataPart2] = useState([])
  const [allproduct , setallproduct] = useState([])
  const [allproductReview , setallproductReview] = useState([])
  const [Average , setAverage] = useState(0)

  const [product , setproduct] = useState([])

  const [Area1 , setArea1] = useState(true)
  const [Area2 , setArea2] = useState(false)
  const [Area3 , setArea3] = useState(false)
  const [MainCatogories , setMainCatogories] = useState([])

  const [dd1 , setdd1] = useState(true)
  const [dd2 , setdd2] = useState(false)


  const [Star1 , setStar1] = useState(0)
  const [Star2 , setStar2] = useState(0)
  const [Star3 , setStar3] = useState(0)
  const [Detail, setDetail] = useState("") 



  const [Changer , setChanger] = useState(0)

  const [status , setstatus] = useState(1)


  const [name, setname] = useState("") 
  const [lname, setlname] = useState("") 
  const [email, setemail] = useState("") 
  const [subject, setsubject] = useState("") 
  const [Message, setMessage] = useState("") 

  const [CategoriesName , setCategoriesName] = useState({})

  const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    const [fg , setfg] = useState(true)
    const [couponShow , setcouponShow] = useState(false)
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [IterationForSlider , setIterationForSlider] = useState(9)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])



    useEffect(() =>{
        window.scrollTo(0, 0)

        let Categoriesname = props.match.params ;
        setCategoriesName(Categoriesname)
        fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })


      fetch("https://halal-dropship-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://halal-dropship-main-back.vercel.app/AllProduct",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       }
      })
      .then(res5=>res5.json())
      .then(res6=>{
       //    console.log(res6);
       const pro = []
       res6.map((res7,i)=>{
           //    console.log(res7.Product_Popular );
           if ( res7.Arrival){
               //    console.log(res7);
               //         // return setproduct1(...product1 , res3)
               pro.push(res7)
           }
       })
       // setproduct(pro.reverse())
       setproduct(pro.slice(0,3))
      //  setdis(true)
      })

        fetch("https://halal-dropship-main-back.vercel.app/AllProduct1/"+Categoriesname.productId,{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res=>res.json())
    .then(res1=>{
        console.log(res1)
        setproductDetail(res1)
        // console.log(res1.Product_Long_Notes.replace("\n","<br>"));
        setDetail(res1[0].Product_Long_Notes)
        for (let i = 1; i < 10; i++) {
          if(res1[0][`Product_Image_Upload${i}`]  !== ""){

          }else{
            console.log(i)
            setIterationForSlider(i)
            break
          }
          
        }
        // carrouselInit()
        // addZoom()
        // imageZoom()
        // console.log(res1[0].Product_Long_Notes,Detail,"jk");
    })
    fetch("https://halal-dropship-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)
        })
        fetch("https://halal-dropship-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        fetch("https://halal-dropship-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
      
      })
    fetch("https://halal-dropship-main-back.vercel.app/allReview",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res3=>res3.json())
    .then(res4=>{
        console.log(res4) 
            var q = 0
            var v = 0
            var p = 0
            var qan = 0
        res4.map((res5)=>{
            // console.log(res5)
           
            if(res5.productId === Categoriesname.productid &&res5.ReviewApproval){
                // console.log(res5.quality,+res5.value,res5.price);
               
                v = res5.value + v
                q =res5.quality + q
                p = res5.price + p
                qan = 15 + qan
            } 
        }) 
                // setq(q)
                // setv(v)
                // setp(p)
                // setqan(qan)
        // const data1 = res4.filter((res5)=>{
        //     return res5.productId === Categoriesname.productid
        // })
        // var q = 0
        // var v = 0
        // var p = 0
        // var qan = 0
        let data2 = res4.filter((res5)=>{
            // q =+ res5.quality
            // v =+ res5.value
            // p =+ res5.price
            // qan =+ 5
            return res5.productId === Categoriesname.productid && res5.ReviewApproval
        })
        
        let total = q+p+v
        console.log(total,q,v,p,qan,"total");
        // if((total*100)/qan >= 80){
        //     document.getElementById("star1").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star2").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star3").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star4").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star5").style.color = "rgb(251, 122, 51)"
        // }
        // else if((total*100)/qan >= 60){
        //     document.getElementById("star1").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star2").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star3").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star4").style.color = "rgb(251, 122, 51)"
            
        // }
        // else if((total*100)/qan >= 40){
        //     document.getElementById("star1").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star2").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star3").style.color = "rgb(251, 122, 51)"

            
        // }
        // else if((total*100)/qan >= 20){
        //     document.getElementById("star1").style.color = "rgb(251, 122, 51)"
        //     document.getElementById("star2").style.color = "rgb(251, 122, 51)"

            
        // }
        // else{
        //     document.getElementById("star1").style.color = "rgb(251, 122, 51)"

        // }
        
        
        
        // setAverage((total*100)/qan)
        setallproductReview(data2)
    })
      var data1 =  JSON.parse(localStorage.getItem("Cart")) 
       if (data1) setDataPart2(data1)

  
    },[])


const setChanger5=(e)=>{
        document.getElementById("change-photo-zoom").src = e
    }




const minus=()=>{
        if(Price > 1)
        setPrice(Price - 1)
        console.log(Price);
    }
    const plus=()=>{
        if(Price < 100)
        setPrice(Price + 1)
        console.log(Price);

    }
  
    const addtocartproduct = () =>{
      // swal("Update!", "Your Item Add in Cart!", "success");
    
      // if(productDetail){
          // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          Swal.fire({
            title: 'Your Item Add in Cart!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Coutinue Shopping',
            denyButtonText: `View Cart`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // Swal.fire('Saved!', '', 'success')
            } else if (result.isDenied) {
              props.history.push("/cart")
              // Swal.fire('Changes are not saved', '', 'info')
            }
          })
    
    
        
          if (productDetail[0].Product_Price_Discounted === null){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : Price ,
                Total_Product_Price  : productDetail[0].Product_Price * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : Price + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price * Price )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

            }
            else if (productDetail[0].Product_Price_Discounted){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : Price ,
                Total_Product_Price  : productDetail[0].Product_Price_Discounted * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : Price + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price_Discounted * Price )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

            }
            else if (productDetail[0].Size_Discounted[0]){
                
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        Size : productDetail[0].Size[Changer],
                        Product_Price_Discounted : productDetail[0].Size_Discounted[Changer],
                        Total_Product_Price  : productDetail[0].Size_Discounted[Changer] * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[Changer]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : Price + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Size_Discounted[Changer] * Price )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

            }
            else{




                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        Size : productDetail[0].Size[Changer],
                        Product_Price_Discounted : productDetail[0].Price[Changer],
                        Total_Product_Price  : productDetail[0].Price[Changer] * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[Changer]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : Price + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Price[Changer] * Price )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }


            }
          
                
          
                          
    
    }
  
    const addtocartproduct123 = () =>{
      swal("Update!", "Your Item Add in Cart!", "success");
    
      // if(productDetail){
          // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          
    
    
        
          if (productDetail[0].Product_Price_Discounted === null){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Total_Product_Price  : productDetail[0].Product_Price * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : 1 + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
            }
            else if (productDetail[0].Product_Price_Discounted){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : 1 + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
            }
            else if (productDetail[0].Size_Discounted[0]){
                
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : 1 ,
                        Size : productDetail[0].Size[0],
                        Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                        Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : 1 + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
            }
            else{
          
          
          
          
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : 1 ,
                        Size : productDetail[0].Size[0],
                        Product_Price_Discounted : productDetail[0].Price[0],
                        Total_Product_Price  : productDetail[0].Price[0] * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : 1 + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
          
            }
          
          
                
    
                          
  props.history.push("/cart")
    }
const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }

const  changePhoto = (e)=>{
    document.getElementById("changes-Photo").src = e
}

const change_detail_area = (e)=>{
    if (e === 1){
        setArea1(true)
        setArea2(false)
        setArea3(false)
    }
    else if (e === 2){
        setArea1(false)
        setArea2(true)
        setArea3(false)
    }
    else if (e === 3){
        setArea1(false)
        setArea2(false)
        setArea3(true)
    }
}

const RatingProduct1 = (e)=>{
    setStar1(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").firstChild.children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").firstChild.children[i].style.color  = "yellow"
    }
}
const RatingProduct2 = (e)=>{
    setStar2(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").children[1].children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    // document.getElementById("Rate").children[1].children[e-1].style.color  = "yellow"
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").children[1].children[i].style.color  = "yellow"
    }
}
const RatingProduct3 = (e)=>{
    setStar3(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").children[2].children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").children[2].children[i].style.color  = "yellow"
    }
}
    // document.getElementById("Rate").children[2].children[e-1].style.color  = "yellow"

// }
const changePrice = (e)=>{
    setChanger(e)
    // console.log("sdcefelwjfklj");
    // if(document.getElementById("PriceChanger")){
        if(productDetail[0].Size_Discounted[0]){
              document.getElementById("PriceChanger2").innerHTML ="Rs : "+ productDetail[0].Price[e]
        // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
        document.getElementById("PriceChanger3").innerHTML =(100 -(productDetail[0].Size_Discounted[e] *100) /  productDetail[0].Price[e]).toFixed(0) +" % off"
        document.getElementById("PriceChanger4").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
    }
    else{
        document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
    }
      
        // "Rs : "+ productDetail[0].Size_Discounted[e]
        // let Price = productDetail[0].Price[e]
        // de.innerHTML = Price
    // }

}

const setReviewChange = ()=>{
    // setChanger(e)
    setArea1(false)
    setArea2(false)
    setArea3(true)
        // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Price[e]
  

}


const submitUserReview = (e)=>{
    e.preventDefault()
    fetch("https://halal-dropship-main-back.vercel.app/createReview",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
            user:name,
            summary:subject ,
            review_message:Message ,
            quality:Star1 ,
            value:Star2 ,
            price:Star3 ,
            productId:CategoriesName.productid
        }),
    })
    .then(res=>res.json())
    .then((res2)=>{        
    if(res2.Error){
        swal(res2.Error);
     }
     else{
        swal("Successfully Submit your Review!");
     }
    })
       
}
// const changePrice1 = (e)=>{
//     setChanger(e)
//     // console.log("sdcefelwjfklj");
//     // if(document.getElementById("PriceChanger")){
//         document.getElementById("PriceChanger2").innerHTML ="Rs : "+ productDetail[0].Price[e]
//         // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         document.getElementById("PriceChanger3").innerHTML =(100 -(productDetail[0].Size_Discounted[e] *100) /  productDetail[0].Price[e]).toFixed(0) +" % off"
//         document.getElementById("PriceChanger4").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         // "Rs : "+ productDetail[0].Size_Discounted[e]
//         // let Price = productDetail[0].Price[e]
//         // de.innerHTML = Price
//     // }

// }
const setShoesSize = (e)=>{
    document.getElementById("myDiv1234").style.display= "block"
    let  m = document.getElementById("myDiv23456")
    m.src = e
}
const setMainPhoto = (e)=>{
    console.log(e);
    document.getElementById("mainphoto").style.display= "block"
    let  m = document.getElementById("mainphoto2")
    m.src = e
}

const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
    document.getElementById("mainphoto").style.display= "none"
}

const setChatNow = ()=>{
    document.getElementById("myDivUmer").style.display= "block"
    // console.log("rtgd")
}
const setChanger22 = (e)=>{
    document.getElementById("myDivUmer").style.display= "none"
  }


  



  const SubmitDataMessage = (e) =>{
    e.preventDefault()
    //   if(message.length >= 1 ){
    //     const msgDetails = {
    //     message : message , 
    //     user_uid_1 : Auth.uid ,
    //     user_uid_2 : specificUserChat.uid ,

    // }
    // // dispatch(sendMessageFromDatabase(msgDetails))
    // setMessage("")
    // if(!Auth.uid ){
    //     console.log("-")
    //     // dispatch(UserKeyUpNoTypingShow(User.uid))
    //     }
    // else{
    //         console.log("-")
    //         // dispatch(UserKeyUpNoTypingShow(Auth.uid))
    //     }
    
    // }
}

const setMess1 = () =>{
    localStorage.setItem("Details Product" , JSON.stringify(productDetail))
    props.history.push("/chat")
}


const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}
    const carrouselInit =() => {

      var carrousel = document.getElementById("carrouselimg1");
      carrousel.style.opacity = "1";
      // setInterval(carrouselAutoChange, 5000);
    
    }
    
    const carrouselAutoChange=()  =>{
      carrouselRight();
    }
    
    
    
    const carrouselChange=(i) => {
    
      var carrousel = document.getElementById("carrouselimg" + i);
      carrousel.style.opacity = "1";
    
    }
    
    const setChan = (i) =>{
        console.log(i)
         if(      document.getElementById("categ"+i).style.display  ==="block")
        document.getElementById("categ"+i).style.display  ="none"
        else
        document.getElementById("categ"+i).style.display  ="block"
      }
      const setChan1 = (i,data) =>{
        //  let  e =  []
        //   for (let s = 0; s < SubCategories.length; s++) {
        //     if(data === SubCategories[s].MainCategories)
        //     e.push( SubCategories[s])
        //   }
        //   console.log(i,e)
        //   // console.log(a)
        //   for (let a = 0; a < e.length; a++) {
        //     console.log(a)
        //      document.getElementById("cate"+a).style.display  ="none"
     
        //    }
        console.log(document.getElementById("cate"+i),i)
           if(      document.getElementById("cate"+i).style.display  ==="block"){
            console.log("yes")
            document.getElementById("cate"+i).style.display  ="none"
          }
          else{
            console.log("yes1")
    
            document.getElementById("cate"+i).style.display  ="block"
          }
              let a =  []
              SubCategories.map((res1,j)=>{
                if(data === res1.MainCategories){
                  a.push(j)
                }
              })
              console.log(a)
          if(a[0] !== i){
              document.getElementById("cate"+a[0]).style.display  ="none"
            }
          // if(i !== 0){
        }
      const setChan2 = (i) =>{
        console.log(i)
         if(      document.getElementById("categp"+i).style.display  ==="block")
        document.getElementById("categp"+i).style.display  ="none"
        else
        document.getElementById("categp"+i).style.display  ="block"
      }
      const setChan3 = (i,data) =>{
        // for (let a = 0; a < SubCategories.length; a++) {
        //  console.log(a)
        //   document.getElementById("catep"+a).style.display  ="none"
      
        // }
        console.log(i)
         if(      document.getElementById("catep"+i).style.display  ==="block")
        document.getElementById("catep"+i).style.display  ="none"
        else
        document.getElementById("catep"+i).style.display  ="block"
      
        let a =  []
        SubCategories.map((res1,j)=>{
          if(data === res1.MainCategories){
            a.push(j)
          }
        })
        console.log(a)
      if(a[0] !== i){
        document.getElementById("catep"+a[0]).style.display  ="none"
      }
      }
      const setChang677 = () =>{
        console.log( document.getElementById("navbar-vertical1"))
        if( document.getElementById("navbar-vertical1").style.display === "none")
        document.getElementById("navbar-vertical1").style.display = "block"
        else
        document.getElementById("navbar-vertical1").style.display = "none"
      }
    const carrouselLeft=()  =>{
      console.log(status,IterationForSlider)

      if(status > 1){
        var carrousel = document.getElementById("carrouselimg" + status);
        carrousel.style.display = "none";
        let a = status - 1
        var carrousel1 = document.getElementById("carrouselimg" + a);
        console.log(carrousel1)
        carrousel1.style.display = "block";
        setstatus(status-1)

      }
else{
  setstatus(IterationForSlider)
}
          }
   
    const carrouselRight=() =>{
      // for (let i = 1; i < 5 ; i++) {
      //   var carrousel = document.getElementById("carrouselimg" + i);
      //   carrousel.style.display = "none";
        
        
      // }
      console.log(status,IterationForSlider)
      if(status < IterationForSlider){
        var carrousel = document.getElementById("carrouselimg" + status);
        carrousel.style.display = "none";
        let a = status + 1
        var carrousel1 = document.getElementById("carrouselimg" + a);
        console.log(carrousel1)
        carrousel1.style.display = "block";
        setstatus(status+1)

      }
else{
  var carrousel = document.getElementById("carrouselimg" + status);
  carrousel.style.display = "none";
  let a = status + 1
  var carrousel1 = document.getElementById("carrouselimg1" );
  console.log(carrousel1)
  carrousel1.style.display = "block";
  setstatus(1)
}

      // let nbCarrousel = 6;
      // let num = 0;
    
      // for (let i = 0; i < nbCarrousel; i++) {
      //     num = i + 1;
      //     var carrousel = document.getElementById("carrouselimg" + num);
      //     if (carrousel.style.display === "none") {
      //       carrousel.style.display = "block";
      //         if (i == 5) {
      //             return carrouselChange(1);
      //         }
      //         return carrouselChange(num + 1);
      //     }
      // }
    }

    // const imageZoom =()=> {
    //   var img, lens, result, cx, cy;
    //   img = document.getElementById("changes-Photo");
    //   result = document.getElementById("changes-Photo");
    //   /*create lens:*/
    //   lens = document.createElement("DIV");
    //   lens.setAttribute("class", "img-zoom-lens");
    //   /*insert lens:*/
    //   img.parentElement.insertBefore(lens, img);
    //   /*calculate the ratio between result DIV and lens:*/
    //   cx = result.offsetWidth / lens.offsetWidth;
    //   cy = result.offsetHeight / lens.offsetHeight;
    //   /*set background properties for the result DIV:*/
    //   result.style.backgroundImage = "url('" + img.src + "')";
    //   result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    //   /*execute a function when someone moves the cursor over the image, or the lens:*/
    //   lens.addEventListener("mousemove", moveLens);
    //   img.addEventListener("mousemove", moveLens);
    //   /*and also for touch screens:*/
    //   lens.addEventListener("touchmove", moveLens);
    //   img.addEventListener("touchmove", moveLens);
    //   function moveLens(e) {
    //     var pos, x, y;
    //     /*prevent any other actions that may occur when moving over the image:*/
    //     e.preventDefault();
    //     /*get the cursor's x and y positions:*/
    //     pos = getCursorPos(e);
    //     /*calculate the position of the lens:*/
    //     x = pos.x - (lens.offsetWidth / 2);
    //     y = pos.y - (lens.offsetHeight / 2);
    //     /*prevent the lens from being positioned outside the image:*/
    //     if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
    //     if (x < 0) {x = 0;}
    //     if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
    //     if (y < 0) {y = 0;}
    //     /*set the position of the lens:*/
    //     lens.style.left = x + "px";
    //     lens.style.top = y + "px";
    //     /*display what the lens "sees":*/
    //     result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    //   }
    //   const getCursorPos = (e)=> {
    //     var a, x = 0, y = 0;
    //     e = e || window.event;
    //     /*get the x and y positions of the image:*/
    //     a = img.getBoundingClientRect();
    //     /*calculate the cursor's x and y coordinates, relative to the image:*/
    //     x = e.pageX - a.left;
    //     y = e.pageY - a.top;
    //     /*consider any page scrolling:*/
    //     x = x - window.pageXOffset;
    //     y = y - window.pageYOffset;
    //     return {x : x, y : y};
    //   }
    // }



   const handleMouseMove = (e) => {
    console.log(e.target.getBoundingClientRect())
      const { left, top, width, height } = e.target.getBoundingClientRect()
      const x = (e.pageX - left) / width * 100
      const y = (e.pageY - top) / height * 100
      document.getElementById("carrouselimg1").style.backgroundPosition = `${x}% ${y}%`
    }

  
    return (
        <>  
     
      
    <div className="container-fluid">
          <div className="row border-top px-xl-5">
            <div className="col-lg-3 d-none d-lg-block"  style={{paddingRight : "0px"}}>
            <a className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100" data-toggle="collapse" href="#navbar-vertical" aria-expanded="false" style={{height: '37px', marginTop: '-1px', padding: '0 30px'}} onClick={()=>setChang677()}>
                <h6 className="m-0" style={{padding:"0px"}}>Categories</h6>
                <KeyboardArrowDownIcon />
              </a>
              <nav className="collapse navbar navbar-vertical navbar-light align-items-start p-0 border-top-0 border-bottom-0" id="navbar-vertical1" style={{position: "absolute",zIndex: "99999"}}>
                
                
                
                
                
                
                
                <div className="navbar-nav w-100" style={{height: '410px',overflow:"scroll"}}>
 







                  {MainCatogories.map((res,i)=>{
                    return (
                      <>
                      
                     
                      <a href className="nav-item nav-link" ><Link  to={"/categories/"+res.MainCategories+"/all-products"}>{res.MainCategories} </Link>
                       {Cate1[i] === "1" ?
<KeyboardArrowDownIcon onClick={()=>setChan(i)}/>
: ""
                       }
                       
                       
                       </a>                     
                     <div className="nav-item dropdown" style={{display : "none"}} id={"categ"+i}>
                      {  SubCategories.map((res1,j)=>{
                        // console.log(res1.MainCategories , res.MainCategories)
                        if(res1.MainCategories === res.MainCategories){
                          return (
                            <>
                            <a className="nav-link" data-toggle="dropdown" style={{padding  :"5px 30px"}}> <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/all-products"}> {res1.CategoriesName}</Link>
                            {Cate2[j] === "1" ?
                            <KeyboardArrowDownIcon  onClick={()=>setChan1(j,res.MainCategories)}/>
: ""
                       }                            </a>
                        <div className="dropdown-menu border-0 rounded-0 w-100 m-0" id={"cate"+j}>
                        {SubClassCategories.map((res2,k)=>{
                        // console.log(res2)
                        if(res2.MainCategories === res.MainCategories && res2.CategoriesName === res1.CategoriesName){
                          return (
                            <Link to={"/categories/"+res.MainCategories+"/"+res1.CategoriesName+"/"+res2.Second_Categories+"/all-products"} className="dropdown-item" style={{padding  :"5px 40px"}}>{res2.Second_Categories}</Link>


                          )
                        } 
                      })
                    }
                       
                        {/* <a href className="dropdown-item">Men's Dresses</a>
                        <a href className="dropdown-item">Women's Dresses</a>
                        <a href className="dropdown-item">Baby's Dresses</a> */}
                      </div>
                            </>
                    
                          )
                        }
                        })
                      }
                      </div>
                     
                      </>
                    )
                  })

                  }
               















                </div>
























              </nav>
            </div>
           
          </div>
        </div>
        {/* Navbar End */}
        {/* Page Header Start */}
        <div className="container-fluid bg-secondary">
          <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '91px'}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3"style={{color : "white"}}>Shop Detail</h1>
            {/* <div className="d-inline-flex"style={{color : "white"}}>
              <p className="m-0"><a href>Home</a></p>
              <p className="m-0 px-2">-</p>
              <p className="m-0">Shop Detail</p>
            </div> */}
          </div>
        </div>
        {/* Page Header End */}
        {/* Shop Detail Start */}
        <div className="container-fluid py-4">
          <div className="row px-xl-5">
          {/* <div class="col-lg-5 pb-5">
                <div id="product-carousel" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner border">
                        <div class="carousel-item active">
                            <img class="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload ?productDetail[0].Product_Image_Upload:""} alt="Image"/>
                        </div>
                        <div class="carousel-item">
                            <img class="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload ?productDetail[0].Product_Image_Upload:""} alt="Image"/>
                        </div>
                        <div class="carousel-item">
                            <img class="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload ?productDetail[0].Product_Image_Upload:""} alt="Image"/>
                        </div>
                        <div class="carousel-item">
                            <img class="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload ?productDetail[0].Product_Image_Upload:""} alt="Image"/>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#product-carousel" data-slide="prev">
                        <i class="fa fa-2x fa-angle-left text-dark"></i>
                    </a>
                    <a class="carousel-control-next" href="#product-carousel" data-slide="next">
                        <i class="fa fa-2x fa-angle-right text-dark"></i>
                    </a>
                </div>
            </div> */}
            <div className="col-lg-5 pb-5">
              <div id="product-carousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner border">
                  <div className="carousel-item active"  id="carrouselimg1">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload} alt="Image"  id="changes-Photo" onClick={()=>setstatus(0)}/>
                  </div>
                  {/* {productDetail[0] && productDetail[0].Product_Image_Upload1 ? */}
                  <div className="carousel-item"  id="carrouselimg2">
                    <img className="w-100 h-100" src={productDetail[0] &&productDetail[0].Product_Image_Upload1} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(1)}/>
                  </div>

                  {/* : ""
} */}
                  {/* {productDetail[0] && productDetail[0].Product_Image_Upload2 ? */}
                  <div className="carousel-item"  id="carrouselimg3">
                    <img className="w-100 h-100" src={productDetail[0] &&productDetail[0].Product_Image_Upload2} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(2)}/>
                  </div>
                  <div className="carousel-item"  id="carrouselimg4">
                    <img className="w-100 h-100" src={productDetail[0] &&productDetail[0].Product_Image_Upload3} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(3)}/>
                  </div>

                  {/* : ""
} */}
                  {/* {productDetail[0] && productDetail[0].Product_Image_Upload3 ? */}
                  <div className="carousel-item" id="carrouselimg5">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload4} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(4)}/>
                  </div>
                  <div className="carousel-item" id="carrouselimg6">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload5} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(5)}/>
                  </div>
                  <div className="carousel-item" id="carrouselimg7">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload6} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(6)}/>
                  </div>
                  <div className="carousel-item" id="carrouselimg8">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload7} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(7)}/>
                  </div>
                  <div className="carousel-item" id="carrouselimg9">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload8} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(8)}/>
                  </div>
                  <div className="carousel-item" id="carrouselimg10">
                    <img className="w-100 h-100" src={productDetail[0] && productDetail[0].Product_Image_Upload9} alt="Image"  id="changes-Photo"  onClick={()=>setstatus(9)}/>
                  </div>

                  {/* : ""
} */}
                 
                </div>
                <a className="carousel-control-prev" href="#header-carousel" data-slide="prev" onClick={()=>carrouselLeft()}>
                  <div className="btn btn-dark" style={{width: '45px', height: '45px'}}>
                    <span className="carousel-control-prev-icon mb-n2" />
                  </div>
                </a>
                <a className="carousel-control-next" href="#header-carousel" data-slide="next" onClick={()=>carrouselRight()}>
                  <div className="btn btn-dark" style={{width: '45px', height: '45px'}}>
                    <span className="carousel-control-next-icon mb-n2" />
                  </div>
                </a>
                {/* <a className="carousel-control-prev" data-slide="prev" onClick={()=>carrouselLeft()}>
                  <KeyboardArrowLeftIcon  style={{color : "grey",}}/>
                </a>
                <a className="carousel-control-next" data-slide="next" onClick={()=>carrouselRight()}>
                  <ArrowForwardIosIcon  style={{color : "grey",}}/>
                </a> */}
              </div>
            </div>
            <div className="col-lg-7 pb-5">
              <h3 className="font-weight-semi-bold">   {productDetail[0] &&productDetail[0].Product_Name}   </h3>
              <div className="d-flex mb-3">
                <div className="text-primary mr-2">
                  {/* <small className="fas fa-star" /> */}
                  <StarBorderIcon/>
                  <StarBorderIcon/>
                  <StarBorderIcon/>
                  <StarBorderIcon/>
                  <StarBorderIcon/>
                  {/* <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star-half-alt" />
                  <small className="far fa-star" /> */}
                </div>
                {/* <small className="pt-1">(50 Reviews)</small> */}
              </div>
              <h3 className="font-weight-semi-bold mb-4">
              
              
              {
                                                                                                  
                                                 productDetail[0] && productDetail[0].Product_Price_Discounted ? 
                                                
                                                <div style={{display : "flex"}}>
                                                    <p className="mtext-106 cl2"><strong className=" h2" style={{color : "black"}}>Rs : {productDetail[0] && (productDetail[0].Product_Price_Discounted).toFixed(0)} </strong></p>
                                                    <div style={{width: "15px"}}></div>
                                                    <p className="mtext-106 cl2" style={{color : "grey" , PaddingTop : "60px" , margin:  "0px" ,paddingTop: "8px"}}><strong  className=" h5" > <s> Rs : {productDetail[0] && (productDetail[0].Product_Price).toFixed(0)} </s></strong></p>
                                                    <div style={{width: "10px"}}></div>
                                                   
                                             
                                                
                                                </div>
                                                : 
                                                    <p style={{color : "black" , PaddingTop : "60px" , margin:  "0px" ,paddingTop: "8px"}}><strong  className=" h2" >  Rs : {productDetail[0] && (productDetail[0].Product_Price).toFixed(0)} </strong></p>
                                                
                                                }
              


              </h3>
              <p className="mb-4" style={{fontSize: "20px"}}>{productDetail[0] &&productDetail[0].Product_Short_Notes}</p>
              {/* <p className="mb-4">{productDetail[0] &&productDetail[0].Product_Long_Notes}</p> */}
              {/* <div className="d-flex mb-3">
                <p className="text-dark font-weight-medium mb-0 mr-3">Sizes:</p>
                <form>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="size-1" name="size" />
                    <label className="custom-control-label" htmlFor="size-1">XS</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="size-2" name="size" />
                    <label className="custom-control-label" htmlFor="size-2">S</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="size-3" name="size" />
                    <label className="custom-control-label" htmlFor="size-3">M</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="size-4" name="size" />
                    <label className="custom-control-label" htmlFor="size-4">L</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="size-5" name="size" />
                    <label className="custom-control-label" htmlFor="size-5">XL</label>
                  </div>
                </form>
              </div>
              <div className="d-flex mb-4">
                <p className="text-dark font-weight-medium mb-0 mr-3">Colors:</p>
                <form>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="color-1" name="color" />
                    <label className="custom-control-label" htmlFor="color-1">Black</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="color-2" name="color" />
                    <label className="custom-control-label" htmlFor="color-2">White</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="color-3" name="color" />
                    <label className="custom-control-label" htmlFor="color-3">Red</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="color-4" name="color" />
                    <label className="custom-control-label" htmlFor="color-4">Blue</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id="color-5" name="color" />
                    <label className="custom-control-label" htmlFor="color-5">Green</label>
                  </div>
                </form>
              </div> */}
              <div className="mb-4 pt-2">
                <div className="input-group quantity mr-3" style={{width: '200px'}}>
                  <div className="input-group-btn">
                    <button className="btn btn-minus" onClick = {()=>minus()} style={{fontWeight: "bold",
    fontSize: "23px",
    padding: "4px 11px", border : "1px solid #285a73"}}> -
                      {/* <i className="fa fa-minus" /> */}
                    </button>
                  </div>
                  <input type="text" className="form-control text-center"   value={Price} onChange={(e)=>setPrice(e.target.value)}  min="1" max="100"/>
                  <div className="input-group-btn">
                    <button className="btn btn-plus"onClick={()=>plus()} style={{fontWeight: "bold",
    fontSize: "21px",
    padding: "4px 11px", border : "1px solid #285a73"}}> +
                      {/* <i className="fa fa-plus" /> */}
                    </button>
                  </div>
                </div>
                <br />
                <button className="btn px-3"style={{color : "black", border : "1px solid #285a73",fontWeight:700 ,fontSize:"17px",textTransform:"capitalize" ,fontFamily: "monospace"}}  onClick={()=>addtocartproduct(productDetail[0])} ><ShoppingCartIcon   style={{padding:"0px 0px" , fontSize : "25px"}} /> Add To Cart</button>
              </div>
              <div className="d-flex pt-2">
                <p className="text-dark font-weight-medium mb-0 mr-2">Share on:</p>
                <div className="col-lg-6 text-center text-lg-right">
              <div className="d-inline-flex align-items-center">
                <a className="text-dark px-2" href>
                  <FacebookIcon />
                </a>
                <a className="text-dark px-2" href>
                  <InstagramIcon />
                </a>
                <a className="text-dark px-2" href>
                  <TwitterIcon />
                </a>
                <a className="text-dark px-2" href>
                  <YouTubeIcon />
                </a>
                <a className="text-dark pl-2" href>
                  <LinkedInIcon />
                </a>
              </div>
            </div>
                {/* <div className="d-inline-flex">
                  <a className="text-dark px-2" href>
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="text-dark px-2" href>
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="text-dark px-2" href>
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a className="text-dark px-2" href>
                    <i className="fab fa-pinterest" />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row px-xl-5">
            <div className="col">
              <div className="nav nav-tabs justify-content-center border-secondary mb-4">
                <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1">Description</a>
                {/* <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-2">Information</a> */}
                <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-3">Reviews (0)</a>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="tab-pane-1">
                  <h4 className="mb-3">Product Description</h4>
                 
                 {productDetail[0] && productDetail[0].videoUrl ?
                  <video controls src={productDetail[0].videoUrl}  style={{maxHeight : "300px"}}></video>
                  : ""
                 }


                  <p style={{maxWidth : "100%",overflow:"scroll"}}> {productDetail[0] &&productDetail[0].Product_Long_Notes ?<div dangerouslySetInnerHTML={{__html: productDetail[0].Product_Long_Notes}} />:""}</p>
                </div>
                {/* <div className="tab-pane fade" id="tab-pane-2">
                  <h4 className="mb-3">Additional Information</h4>
                  <p>Eos no lorem eirmod diam diam, eos elitr et gubergren diam sea. Consetetur vero aliquyam invidunt duo dolores et duo sit. Vero diam ea vero et dolore rebum, dolor rebum eirmod consetetur invidunt sed sed et, lorem duo et eos elitr, sadipscing kasd ipsum rebum diam. Dolore diam stet rebum sed tempor kasd eirmod. Takimata kasd ipsum accusam sadipscing, eos dolores sit no ut diam consetetur duo justo est, sit sanctus diam tempor aliquyam eirmod nonumy rebum dolor accusam, ipsum kasd eos consetetur at sit rebum, diam kasd invidunt tempor lorem, ipsum lorem elitr sanctus eirmod takimata dolor ea invidunt.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item px-0">
                          Sit erat duo lorem duo ea consetetur, et eirmod takimata.
                        </li>
                        <li className="list-group-item px-0">
                          Amet kasd gubergren sit sanctus et lorem eos sadipscing at.
                        </li>
                        <li className="list-group-item px-0">
                          Duo amet accusam eirmod nonumy stet et et stet eirmod.
                        </li>
                        <li className="list-group-item px-0">
                          Takimata ea clita labore amet ipsum erat justo voluptua. Nonumy.
                        </li>
                      </ul> 
                    </div>
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item px-0">
                          Sit erat duo lorem duo ea consetetur, et eirmod takimata.
                        </li>
                        <li className="list-group-item px-0">
                          Amet kasd gubergren sit sanctus et lorem eos sadipscing at.
                        </li>
                        <li className="list-group-item px-0">
                          Duo amet accusam eirmod nonumy stet et et stet eirmod.
                        </li>
                        <li className="list-group-item px-0">
                          Takimata ea clita labore amet ipsum erat justo voluptua. Nonumy.
                        </li>
                      </ul> 
                    </div>
                  </div>
                </div> */}
                <div className="tab-pane fade" id="tab-pane-3">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mb-4">1 review for "Colorful Stylish Shirt"</h4>
                      <div className="media mb-4">
                        <img src="img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{width: '45px'}} />
                        <div className="media-body">
                          <h6>John Doe<small> - <i>01 Jan 2045</i></small></h6>
                          <div className="text-primary mb-2">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star-half-alt" />
                            <i className="far fa-star" />
                          </div>
                          <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="mb-4">Leave a review</h4>
                      <small>Your email address will not be published. Required fields are marked *</small>
                      <div className="d-flex my-3">
                        <p className="mb-0 mr-2">Your Rating * :</p>
                        <div className="text-primary">
                          <i className="far fa-star" />
                          <i className="far fa-star" />
                          <i className="far fa-star" />
                          <i className="far fa-star" />
                          <i className="far fa-star" />
                        </div>
                      </div>
                      <form>
                        <div className="form-group">
                          <label htmlFor="message">Your Review *</label>
                          <textarea id="message" cols={30} rows={5} className="form-control" defaultValue={""} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Your Name *</label>
                          <input type="text" className="form-control" id="name" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Your Email *</label>
                          <input type="email" className="form-control" id="email" />
                        </div>
                        <div className="form-group mb-0">
                          <input type="submit" defaultValue="Leave Your Review" className="btn btn-primary px-3" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Shop Detail End */}
        {/* Products Start */}
        {/* <div className="container-fluid py-5">
          <div className="text-center mb-4">
            <h2 className="section-title px-5"><span className="px-2">You May Also Like</span></h2>
          </div>
          <div className="row px-xl-5">
            <div className="col">
              <div className="owl-carousel related-carousel">
                <div className="card product-item border-0">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src="img/product-1.jpg" alt="" />
                  </div>
                  <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                    <div className="d-flex justify-content-center">
                      <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$123.00</del></h6>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1" />View Detail</a>
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1" />Add To Cart</a>
                  </div>
                </div>
                <div className="card product-item border-0">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src="img/product-2.jpg" alt="" />
                  </div>
                  <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                    <div className="d-flex justify-content-center">
                      <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$123.00</del></h6>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1" />View Detail</a>
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1" />Add To Cart</a>
                  </div>
                </div>
                <div className="card product-item border-0">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src="img/product-3.jpg" alt="" />
                  </div>
                  <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                    <div className="d-flex justify-content-center">
                      <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$123.00</del></h6>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1" />View Detail</a>
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1" />Add To Cart</a>
                  </div>
                </div>
                <div className="card product-item border-0">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src="img/product-4.jpg" alt="" />
                  </div>
                  <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                    <div className="d-flex justify-content-center">
                      <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$123.00</del></h6>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1" />View Detail</a>
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1" />Add To Cart</a>
                  </div>
                </div>
                <div className="card product-item border-0">
                  <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src="img/product-5.jpg" alt="" />
                  </div>
                  <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                    <div className="d-flex justify-content-center">
                      <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$123.00</del></h6>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between bg-light border">
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1" />View Detail</a>
                    <a href className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1" />Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        </>
    )
}

export default SubCategoriesProductDetails;
