import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import par1 from '../assets/images/par1.jpg';
// import par2 from '../assets/images/par2.jpg';
// import par3 from '../assets/images/par3.jpg';
// import par4 from '../assets/images/par4.jpg';
// import par5 from '../assets/images/par5.jpg';
// import par6 from '../assets/images/par6.jpg';
// import back from '../assets/images/back.jpg';
// import bigPhoto from "../assets/images/bigPhoto.jpg"
// import u5 from '../assets/images/u5.jfif';

// import Ban1 from "../assets/images/banner1.jpg"
// import Ban2 from "../assets/images/banner2.jpg"

import p1 from "./images/p1.JPG"
import p2 from "./images/p2.JPG"
import p3 from "./images/p3.JPG"
import p4 from "./images/p4.JPG"

import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ReplayIcon from '@material-ui/icons/Replay';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import swal from 'sweetalert';



import sli1 from "./images/sli1.png"
import sli2 from "./images/sli2.png"
import sli3 from "./images/sli3.jpg"
import sli4 from "./images/sli4.jpg"



import z1  from "./img/img/1.png"
import z2  from "./img/img/2.png"
import z3  from "./img/img/3.png"
import z4  from "./img/img/4.png"





const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{

        window.scrollTo(0, 0)
        if(JSON.parse(localStorage.getItem("user main"))){
            props.history.push("/home1")
            
        }
        else{
            

        }
        fetch("https://student1stop-backend-vercel.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        

        fetch("https://student1stop-backend-vercel.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0]);
            setSliderPhoto(res1)
        
        })

        fetch("https://student1stop-backend-vercel.vercel.app/BannerPhoto",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res22=>res22.json())
        .then(res222=>{
            console.log(res222[0]);
            setBannerPhoto(res222)
        
        })

        fetch("https://student1stop-backend-vercel.vercel.app/arrival-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setArrivalPhoto(res3)
        
        })




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://student1stop-backend-vercel.vercel.app/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

        fetch("https://student1stop-backend-vercel.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct(pro.slice(0,3))
    setdis(true)
   })
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])

 const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
 
 
 const cate =(Categories) =>{
     var Cat1 = Categories.split(" ").join("-")
     
     localStorage.setItem("Cate" , JSON.stringify(Categories) )
     
     setTimeout(()=>{
            props.history.push(`/shop/categories/${Cat1}`)
        },1500)
    }
    
    
    const settoBags = (data111) =>{
    //    localStorage.setItem("Data" , JSON.stringify(data) )
       console.log(data111);


       if(data111.ProductCreater !== "60a3c644e4520a12c80a6f52"){
        console.log("yes ");
        fetch("https://student1stop-backend-vercel.vercel.app/ChangePercentageInOrder/"+data111.ProductCreater,{
                            method: "POST",
                            headers :  {
                                "Content-Type" : "application/json" , 
                            }
                        })
                        .then(res=>res.json())
                        .then((res1)=>{ 
                            console.log(res1);
                            // if(!res1 === null){
document.getElementById("myDi").style.visibility = "visible"
    setTimeout(()=>{
        document.getElementById("myDi").style.visibility = "hidden"

    },1000)
    if (data111.Product_Price_Discounted === null){
        const data =  {...data111 ,
                    
                        Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
                    DeliveryStatus : "Pending",
                     Pieces :1,
                     Total_Product_Price  : data111.Product_Price *1}
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data111._id){
                console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        // console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 

        if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
         
        }
        else{
            console.log("Differet");
            var data2 = [...data1 , data]
        
       localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
    }
    else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
      
    }

    }
    else if (data111.Product_Price_Discounted){
        const data =  {...data111 ,
                    
                        Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
                    DeliveryStatus : "Pending",
                     Pieces :1,
                     Total_Product_Price  : data111.Product_Price_Discounted *1}
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data111._id){
                console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        // console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 

        if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
         
        }
        else{
            console.log("Differet");
            var data2 = [...data1 , data]
        
       localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
    }
    else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
      
    }

    }
    else if (data111.Size_Discounted[0]){
        const data =  {...data111 ,
            Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
            DeliveryStatus : "Pending",
            Pieces :1,
            Size : data111.Size[0],
            Product_Price_Discounted : data111.Size_Discounted[0],
            Total_Product_Price  : data111.Size_Discounted[0] *1}
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data111._id &&  item.Size === data111.Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces :1+ item.Pieces,
                        Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
                }
                else{
                    console.log("double not match");
                    return item
                }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            // console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )
            
            }
            else{
                console.log("Differet");
                var data2 = [...data1 , data]
            
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }
    }


    else{




                        const data =  {...data111 ,
                                Percentage : data111.Percentage === 0 ? res1.Percentage : data111.Percentage,
                            DeliveryStatus : "Pending",
                            Pieces :1,
                            Size : data111.Size[0],
                            Product_Price_Discounted : data111.Price[0],
                            Total_Product_Price  : data111.Price[0] *1}
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data111._id &&  item.Size === data111.Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces :1+ item.Pieces,
                        Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
                }
                else{
                    console.log("double not match");
                    return item
                }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            // console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )
              
            }
            else{
                console.log("Differet");
                var data2 = [...data1 , data111]
             
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
      
            }


    }


  })
    

}













else{
  
                document.getElementById("myDi").style.visibility = "visible"
                setTimeout(()=>{
                document.getElementById("myDi").style.visibility = "hidden"

                },1000)
                // console.log(productDetail);
                if (data111.Product_Price_Discounted === null){
                const data =  {...data111 ,
                    Percentage : 0,
                    DeliveryStatus : "Pending",
                    Pieces :1,
                    Total_Product_Price  : data111.Product_Price *1}
                var data1 = JSON.parse(localStorage.getItem("Cart")) 
                console.log(  data1);
                if (data1){
                    console.log(  data111._id);
                var data3 = data1.map((item) => {
                    console.log(item._id , data111._id);
                    if(item._id === data111._id ){
                        console.log("double");
                ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
                }
                else{
                console.log("double not match");
                return item
                }

                })
                var data5 =  JSON.parse(localStorage.getItem("double")) 
                // console.log(DataPart2.length, data3.length,data5);
                var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )

                }
                else{
                console.log("Differet");
                var data2 = [...data1 , data111]

                localStorage.setItem("Cart" , JSON.stringify(data2) )
                }
                }
                else{
                console.log("1");
                localStorage.setItem("Cart" , JSON.stringify([data]) )

                }

                }
                else if (data111.Product_Price_Discounted){
                const data =  {...data111 ,
                    Percentage : 0,
                    DeliveryStatus : "Pending",
                    Pieces :1,
                    Total_Product_Price  : data111.Product_Price_Discounted *1}
                var data1 = JSON.parse(localStorage.getItem("Cart")) 
                console.log(  data1);
                if (data1){
                    console.log(  data111._id);
                var data3 = data1.map((item) => {
                    console.log(item._id , data111._id);
                    if(item._id === data111._id ){
                        console.log("double");
                ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
                }
                else{
                console.log("double not match");
                return item
                }

                })
                var data5 =  JSON.parse(localStorage.getItem("double")) 
                // console.log(DataPart2.length, data3.length,data5);
                var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )

                }
                else{
                console.log("Differet");
                var data2 = [...data1 , data111]

                localStorage.setItem("Cart" , JSON.stringify(data2) )
                }
                }
                else{
                console.log("1");
                localStorage.setItem("Cart" , JSON.stringify([data]) )

                }

                }
                else if (data111.Size_Discounted[0]){
                    const data =  {...data111 ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces :1,
                        Size : data111.Size[0],
                        Product_Price_Discounted : data111.Size_Discounted[0],
                        Total_Product_Price  : data111.Size_Discounted[0] *1}
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data111._id &&  item.Size === data111.Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces :1+ item.Pieces,
                    Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            // console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data111]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

                }
                else{




                        const data =  {...data111 ,
                            Percentage : 0,
                            DeliveryStatus : "Pending",
                            Pieces :1,
                            Size : data111.Size[0],
                            Product_Price_Discounted : data111.Price[0],
                            Total_Product_Price  : data111.Price[0] *1}
                var data1 = JSON.parse(localStorage.getItem("Cart")) 
                if (data1){
                var data3 = data1.map((item) => {
                    if(item._id === data111._id &&  item.Size === data111.Size[0]){
                        console.log("double");
                ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces :1+ item.Pieces,
                        Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
                }
                else{
                    console.log("double not match");
                    return item
                }

                })
                var data5 =  JSON.parse(localStorage.getItem("double")) 
                // console.log(DataPart2.length, data3.length,data5);
                var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                if(data10.length=== data3.length && data5){
                console.log("double remove");
                localStorage.removeItem("double")
                localStorage.setItem("Cart" , JSON.stringify(data3) )

                }
                else{
                console.log("Differet");
                var data2 = [...data1 , data111]

                localStorage.setItem("Cart" , JSON.stringify(data2) )
                }
                }
                else{
                console.log("1");
                localStorage.setItem("Cart" , JSON.stringify([data]) )

                }


                }


                    }


    }

const setsellyourad = () =>{

    if(JSON.parse(localStorage.getItem("user main")))
    props.history.push("/post-your-ad")
    else{
        swal("Login is Must to Post Your Ad")
    }
}
        return (
            
            <div className="site-wrap">
<center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>


        {/* <div className='fdgrg' style={{position: "fixed",bottom: "43px",left: "45%",zIndex: "4"}}>
             <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>setsellyourad()}>SELL</button>
</div> */}

{MainCatogories.length === 0?
   <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
        // style={{position : "fixed",    left: "35%",top:"20%",zIndex: "9999"}}
      /> 
      :""
}











<div className="container-fuild" style={{margin : "20px auto" , width : "95%"}}>
                            <div className="row justify-content-center" style={{margin: "auto"}}>





     <div className="col-lg-8 col-md-8 col-sm-12 pb-1 change-margin-5">








<div id="header-carousel" className="carousel slide change-auto-size-image1" data-ride="carousel">
                <div className="carousel-inner">

                  <div className="carousel-item active change-size-image" style={{height: '410px'}}>
                    <img className="img-fluid change-auto-size-image change-none1" src={sli2} alt="Image"  style={{objectFit :"fill" , width:  "100%" , height : "100%" }}/>
                    <Link to="/user/signup"><img className="img-fluid change-auto-size-image change-none2" src={sli3} alt="Image"  style={{objectFit :"fill" , width:  "100%" , height : "100%" }}/></Link>
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center change-display-2">
                      <div className="p-3 change-margin-top1" style={{maxWidth: '700px',marginTop: "220px",marginLeft : "90px"}}>
                        {/* <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4> */}
                        <Link to="/user/signup"><a href className="btn btn-light py-2 px-3" style={{color :"white",background : "#285a73",border : "none"}}>Register Now</a></Link>
                      </div>
                    </div>
       
                  </div>
                     
                </div>
              
              </div>





              </div>






            <div className="col-lg-4 col-md-4 col-sm-12 pb-1">




<div id="header-carousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner change-auto-size-image2"     style={{margin : "0px 20px"}}>

                  <div className="carousel-item active" style={{height: '410px'}}>
                    <img className="img-fluid change-none1" src={sli1} alt="Image"  style={{objectFit :"fill" ,width: "100%", height: "100%"}}/>
                    <a href="https://halaldropshipping-wholesaler.netlify.app/"><img className="img-fluid change-none2" src={sli4} alt="Image"  style={{objectFit :"fill" ,width: "100%", height: "80%"}}/></a>
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center change-display-2">
                      <div className="p-3 change-margin-top" style={{maxWidth: '700px',marginTop: "18px"}}>
                        {/* <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4> */}
                  <a href="https://halaldropshipping-wholesaler.netlify.app/" className="btn btn-light py-2 px-3" style={{color :"white",background : "#285a73",border : "none"}}>Register Now</a>
                      </div>
                    </div>
                  </div>
                   
                 
                </div>

                </div>


                </div>







                </div>
                </div>
             
               




                <div className="container" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div>
                                 <center>
                                       <h3 style={{color  : "black",fontSize:"30px",fontWeight : "bold"}} className="change-h3-tag">Start Your Online Business<br /> with  Zero Investment on HalalDropShipping
</h3>
<br />
                                    <p style={{color  : "black",fontSize: "17px",
    fontWeight: "500",width  : "50%"}} className="change-width-para1">HalalDropShipping is a revolutionary marketplace in Pakistan that offers a wide range of products for your customers' needs. Become a commission agent and start your own online business by sharing our products online or within your social circle, all without any initial investment.
                                         <br />
                                         <br /> 
With the help of technology and data, HalalDropShipping provides the best user experience, empowering you to achieve more. Our goal is to create over a million micro-entrepreneurs in Pakistan. Join us today and start your journey towards financial independence.

</p>
                                
                                 </center>
                                 
                                    
                                </div>
                            </div>
                        </div>
                </div>

<br />
<br />

<div className='change-ssection-width-responsive-1'> 

                <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
                <center>
                        <h4 className='change-padd' style={{color : "black",fontSize : "26px",fontWeight : "600",padding  :"0px 50px"}}>To establish your e-commerce presence and sell online using our Halal dropshipping portal, follow these steps:
</h4>
                        </center> 
                        <div className="row">
                            <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    {/* <img className="img-fluid" src={SliderPhoto[0] &&SliderPhoto[0].photo1 &&SliderPhoto[0].photo1} alt="Image"  style={{objectFit :"fill" ,width: "100%", height: "100%"}}/> */}
                    <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                      <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Create an online store and profiles to showcase your products and inventory available on our platform.</h5>
                        {/* <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a> */}
                      </div>
                    </div>
                  </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-12" style={{textAlign:"center"}}>
<div style={{padding: "14px 20px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    color  : "black",
    fontWeight : "bold"}}>
    1</div>                    
<div style={{height: "300px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>



                <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    <img className="img-fluid" src={z1} alt="Image"  style={{objectFit :"none" ,width: "100%", height: "100%",padding  :"20px 0px"}}/>
                    {/* <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                </div>



                        </div>
                    
                    <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
                        <div className="row">
                            <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    <img className="img-fluid" src={z2} alt="Image"  style={{objectFit :"none" ,width: "100%", height: "100%"}}/>
                    {/* <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-12" style={{textAlign:"center"}}>
<div style={{padding: "14px 20px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    color  : "black",
    fontWeight : "bold"}}>
    2</div>                    
<div style={{height: "300px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>



                <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    {/* <img className="img-fluid" src={SliderPhoto[0] && SliderPhoto[0].photo1 &&SliderPhoto[0].photo1} alt="Image"  style={{objectFit :"fill" ,width: "100%", height: "100%"}}/> */}
                    <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Place orders on our portal for your customers, providing their details along with your desired profit margin.
</h5>

                      </div>
                    </div>
                  </div>
                            </div>
                </div>



                        </div>
                <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
                        <div className="row">
                            <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    {/* <img className="img-fluid" src={SliderPhoto[0] &&SliderPhoto[0].photo1 &&SliderPhoto[0].photo1} alt="Image"  style={{objectFit :"fill" ,width: "100%", height: "100%"}}/> */}
                    <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• We will handle packaging and shipping, ensuring your brand name is printed on the shipping label.</h5>

                      </div>
                    </div>
                  </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-12" style={{textAlign:"center"}}>
<div style={{padding: "14px 20px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    color  : "black",
    fontWeight : "bold"}}>
    3</div>                    
<div style={{height: "300px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>


                <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    <img className="img-fluid" src={z3} alt="Image"  style={{objectFit :"none" ,width: "100%", height: "100%",padding  :"20px 0px"}}/>
                    {/* <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                </div>



                        </div>
                <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
                        <div className="row">
                            <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    <img className="img-fluid" src={z4} alt="Image"  style={{objectFit :"none" ,width: "100%", height: "100%"}}/>
                    {/* <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-12" style={{textAlign:"center"}}>
<div style={{padding: "14px 20px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    color  : "black",
    fontWeight : "bold"}}>
    4</div>                    
<div style={{height: "200px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>


                <div className="col-xl-5 col-lg-4 col-md-12">
                            <div className="carousel-item active" style={{height: '310px'}}>
                    {/* <img className="img-fluid" src={SliderPhoto[0] && SliderPhoto[0].photo1 &&SliderPhoto[0].photo1} alt="Image"  style={{objectFit :"fill" ,width: "100%", height: "100%"}}/> */}
                    <div className="carousel-caption change-background-color d-flex flex-column align-items-center justify-content-center">
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Our wholesale prices are fixed from the beginning, and we will transfer your commission to your account.
</h5>

                      </div>
                    </div>
                  </div>
                            </div>
                </div>
<br />
<center>
                    <p className='change-padd' style={{fontSize : "16px",fontWeight:"500",color : "black",padding : "10px 50px"}}>By partnering with our Halal dropshipping portal, you can easily set up your online store and sell products without worrying about inventory, packaging, or shipping. Our platform offers a wide selection of products, and we ensure prompt and reliable delivery to your customers. Start building your e-commerce presence today and reach a broader audience!</p>
</center>
                        </div>

</div>      
            
                 <div className='change-ssection-width-responsive-2'> 
                 <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
                <center>
                        <h4 className='change-padd' style={{color : "black",fontSize : "26px",fontWeight : "600",padding  :"0px 50px"}}>To establish your e-commerce presence and sell online using our Halal dropshipping portal, follow these steps:
</h4>
                        </center> 
                        <div className="row change-design-row">
                             <div className="col-xl-2 col-lg-2 col-md-2 change-width-size1" style={{textAlign:"center"}}>
<div style={{padding: "9px 4px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    color  : "black",
    fontSize : "24px",
    fontWeight : "bold"}}>
    1</div>                    
<div style={{height: "340px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>

                       


                <div className="col-xl-10 col-lg-10 col-md-10 change-width-size2">
                            <div className="carousel-item active" style={{height: '310px',margin :  "auto"}}>
                    <img className="img-fluid" src={z1} alt="Image"  style={{width: "350px", height: "300px",padding  :"20px 0px"}}/>
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3 change-h3-width" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Create an online store and profiles to showcase your products and inventory available on our platform.</h5>
                        {/* <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a> */}
                      </div>
                    {/* <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                </div>




                        </div>



                 <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
    
                        <div className="row change-design-row">
                             <div className="col-xl-2 col-lg-2 col-md-2 change-width-size1" style={{textAlign:"center"}}>
<div style={{padding: "9px 4px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    fontSize : "24px",

    color  : "black",
    fontWeight : "bold"}}>
    2</div>                    
<div style={{height: "340px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>

                       


                <div className="col-xl-10 col-lg-10 col-md-10 change-width-size2">
                            <div className="carousel-item active" style={{height: '310px',margin :  "auto"}}>
                    <img className="img-fluid" src={z2} alt="Image"  style={{width: "350px", height: "300px",padding  :"20px 0px"}}/>
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3 change-h3-width" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Create an online store and profiles to showcase your products and inventory available on our platform.</h5>
                        {/* <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a> */}
                      </div>
                    {/* <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                </div>




                        </div>
                 <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
               
                        <div className="row change-design-row">
                             <div className="col-xl-2 col-lg-2 col-md-2 change-width-size1" style={{textAlign:"center"}}>
                             <div style={{padding: "9px 4px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    fontSize : "24px",

    color  : "black",
    fontWeight : "bold"}}>
    3</div>                    
<div style={{height: "340px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>

                       


                <div className="col-xl-10 col-lg-10 col-md-10 change-width-size2">
                            <div className="carousel-item active" style={{height: '310px',margin :  "auto"}}>
                    <img className="img-fluid" src={z3} alt="Image"  style={{width: "350px", height: "300px",padding  :"20px 0px"}}/>
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3 change-h3-width" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Create an online store and profiles to showcase your products and inventory available on our platform.</h5>
                        {/* <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a> */}
                      </div>
                    {/* <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                </div>




                        </div>
                 <div className="container-fuild" style={{width : "99%" ,margin: "0px auto",padding : "0px 30px"}}>
              
                        <div className="row change-design-row">
                             <div className="col-xl-2 col-lg-2 col-md-2 change-width-size1" style={{textAlign:"center"}}>
                             <div style={{padding: "9px 4px",
    border: "2px solid red",
    width: "61px",
    margin: "auto",
    borderRadius: "50px",
    fontSize : "24px",

    color  : "black",
    fontWeight : "bold"}}>
    4</div>                    
<div style={{height: "340px",
    border: "1px solid red",
    margin: "auto",
    width: "2px"}}>
    </div>                    
                            </div>

                       


                <div className="col-xl-10 col-lg-10 col-md-10 change-width-size2">
                            <div className="carousel-item active" style={{height: '310px',margin :  "auto"}}>
                    <img className="img-fluid" src={z4} alt="Image"  style={{width: "350px", height: "300px",padding  :"20px 0px"}}/>
                    <div style={{maxWidth: '700px',padding  :"0px 0px 40px 0px"}}>
                        {/* <h3 className="display-4 text-black font-weight-semi-bold mb-4">Nifticart</h3> */}
                        <h5 className="text-uppercase text-black mb-3 change-h3-width" style={{color : "black",fontWeight  :"500",width: "445px"}}>	• Create an online store and profiles to showcase your products and inventory available on our platform.</h5>
                        {/* <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a> */}
                      </div>
                    {/* <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                      <div className="p-3" style={{maxWidth: '700px'}}>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">Nifticart</h3>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">online shopping store</h4>
                        <a href className="btn btn-light py-2 px-3" style={{color :"#D19C97"}}>Shop Now</a>
                      </div>
                    </div> */}
                  </div>
                            </div>
                </div>




                        </div>

</div>      
               

<br />






































                        <center>
        <h3  style={{color : "black",fontWeight : "bold"}}>

           <b>Dropship Complete Guide Video Step by Step
 </b> 
           </h3>

{/* <div className="container-fuild" style={{width :"91%", margin :  "auto"}}><div className="row" >
  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
<iframe src="https://www.youtube.com/embed/MrRNBL62Ktw" className="youtube-link-responsive" width="100%" height="455px" title="YouTube video player" frameborder="0" allow="fullscreen;"  ></iframe> 
  </div>


  </div>
  </div> */}
</center>















            </div>
        )
    
}

export default Home;