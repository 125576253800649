import React, { Component } from 'react';
import {Link ,useHistory} from "react-router-dom"
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SendIcon from '@material-ui/icons/Send';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import { Flare } from '@material-ui/icons';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
// import logo1 from '../assets/images/logo3.png';
import logo1 from '../assets/images/hds logo1.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
class Footer extends Component {
  
  //  history = useHistory();
  render() {
        return (
            <>
          <div className="container-fluid text-dark mt-5 pt-5" style={{background : "#285a73"}}>
          <div className="row px-xl-5 pt-5">
            <div className="col-lg-8 col-md-12 mb-5 pr-3 pr-xl-5">
              <a href className="text-decoration-none">
                <h1 className="mb-4 display-5 font-weight-semi-bold" ><img src={logo1} alt="" style={{width:"131px",height:"87px"}}/> </h1>
              </a>
              <p style={{fontSize : "14px",color : "white"}}> HalalDropshipping is committed to delivering quality products at fair and affordable prices, while also prioritizing excellent customer service. We strive to understand our customers' needs and provide them with the best possible products, services, and solutions. At the core of our mission is a dedication to quality, which helps us make our customers' lives easier. Trust NIFTICART for the best value and service in the industry.</p>
              <p className="mb-2" style={{color : "white"}}><LocationOnIcon/> office # F127 Central Mall Marston Rd, <br /> Saddar Preedy Quarters, Karachi City, Sindh 74400</p>
              <p className="mb-2" style={{color : "white"}}><SendIcon/> admin.hds@gmail.com</p>
              <p className="mb-0" style={{color : "white"}}><PhoneForwardedIcon /> +92 3311487638</p>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <h5 className="font-weight-bold mb-4" style={{color : "white"}} >Categories</h5>
                  <div className="d-flex flex-column justify-content-start" style={{color : "white"}}>
                    <a className="mb-2" href="https://halaldropshipping.pk/" style={{color : "white"}}><KeyboardArrowRightSharpIcon/>Home</a>
                    {/* <a className="mb-2" href="shop.html"><KeyboardArrowRightSharpIcon/>Our Shop</a> */}
                    {/* <a className="mb-2" href="detail.html"><KeyboardArrowRightSharpIcon/>Shop Detail</a> */}
                    <a className="mb-2" href="https://halaldropshipping.pk/cart" style={{color : "white"}}><KeyboardArrowRightSharpIcon/>Shopping Cart</a>
                    <a className="mb-2" href="https://halaldropshipping.pk/checkout" style={{color : "white"}}><KeyboardArrowRightSharpIcon/>Checkout</a>
                    <a className="mb-2" href="https://halaldropshipping.pk/help" style={{color : "white"}}><KeyboardArrowRightSharpIcon/>FAQs</a>
                    {/* <a className="text-dark" href="contact.html"><KeyboardArrowRightSharpIcon/>Contact Us</a> */}
                  </div>
                </div>
                {/* <div className="col-md-4 mb-5">
                  <h5 className="font-weight-bold text-dark mb-4">Quick Links</h5>
                  <div className="d-flex flex-column justify-content-start">
                    <a className="text-dark mb-2" href="index.html"><KeyboardArrowRightSharpIcon/>Home</a>
                    <a className="text-dark mb-2" href="shop.html"><KeyboardArrowRightSharpIcon/>Our Shop</a>
                    <a className="text-dark mb-2" href="detail.html"><KeyboardArrowRightSharpIcon/>Shop Detail</a>
                    <a className="text-dark mb-2" href="cart.html"><KeyboardArrowRightSharpIcon/>Shopping Cart</a>
                    <a className="text-dark mb-2" href="checkout.html"><KeyboardArrowRightSharpIcon/>Checkout</a>
                    <a className="text-dark" href="contact.html"><KeyboardArrowRightSharpIcon/>Contact Us</a>
                  </div>
                </div> */}
                {/* <div className="col-md-4 mb-5">
                  <h5 className="font-weight-bold text-dark mb-4">Newsletter</h5>
                  <form action>
                    <div className="form-group">
                      <input type="text" className="form-control border-0 py-4" placeholder="Your Name" required="required" />
                    </div>
                    <div className="form-group">
                      <input type="email" className="form-control border-0 py-4" placeholder="Your Email" required="required" />
                    </div>
                    <div>
                      <button className="btn btn-primary btn-block border-0 py-3" type="submit">Subscribe Now</button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row border-top border-light mx-xl-5 py-4">
            <div className="col-md-6 px-xl-0">
              <p className="mb-md-0 text-center text-md-left" style={{color : "white"}}>
                © <a className=" font-weight-semi-bold" href="#" style={{color : "white"}}>Nifti Cart</a>. All Rights Reserved.
                 {/* Designed */}
                {/* by */}
                {/* <a className="text-dark font-weight-semi-bold" href="https://nifticart.com/">Nifti Cart</a> */}
              </p>
            </div>
            <div className="col-md-6 px-xl-0 text-center text-md-right">
              <img className="img-fluid" src="img/payments.png" alt="" />
            </div>
          </div>
        </div>
            </>
        )
    }
}

export default Footer;