import React,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import firebase from './firebase'
import  "firebase/auth";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";
import cnic1 from "./images/cnic1.JPG"
import cnic2 from "./images/cnic2.JPG"


const Signup = (props) => {
    const [email ,  setemail ] = useState("")
    const [pass ,  setpass ] = useState("")
    const [user ,  setuser ] = useState("")
    const [phone ,  setphone ] = useState("+92")
    const [code ,  setcode ] = useState("")
    const [CINC ,  setCINC ] = useState("")
    const [CINC1 ,  setCINC1 ] = useState("")
    const [CINC2 ,  setCINC2 ] = useState("")
    const [BankName ,  setBankName ] = useState("")
    const [AccountOwnerName ,  setAccountOwnerName ] = useState("")
    const [AccountNumberIBAN ,  setAccountNumberIBAN ] = useState("")
    const [SellingStrategy ,  setSellingStrategy ] = useState("")
    const [gy ,  setgy ] = useState(true)
    const [chack1, setchack1] = useState(false) 





    useEffect(() => {
      if (JSON.parse(localStorage.getItem("set user value"))){
            setuser(JSON.parse(localStorage.getItem("set user value")).user)
            setemail(JSON.parse(localStorage.getItem("set user value")).email)
            setpass(JSON.parse(localStorage.getItem("set user value")).pass)
            setphone(JSON.parse(localStorage.getItem("set user value")).phone)
      }
        
      
           }, [])
  
const postData = () =>{

    if(!chack1){
        // if (email === "projectpharma874@gmail.com")
        swal("Check the Term & Conditioms !" )
           return
        }
    if(email.length === 0){
        // if (email === "projectpharma874@gmail.com")
        swal("Enter Your Correct Email !" )
           return
        }
    if(pass.length === 0){
        swal("Enter The Password !"  )
           return
         }
         console.log(phone.length , 13)
         if(phone.length !== 13){
          swal("Enter The Phone Number !"  )
             return
           }
           const data1 = new FormData()
           data1.append("file", CINC1)
           data1.append("upload_preset", "commerce")
           data1.append("cloud_name", "freedeveloperss123")
           fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
               method : "POST",
               body : data1 , 
           })
           .then(res7=>res7.json())
           .then((res8)=>{
            const data2 = new FormData()
            data2.append("file", CINC2)
            data2.append("upload_preset", "commerce")
            data2.append("cloud_name", "freedeveloperss123")
            fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                method : "POST",
                body : data2 , 
            })
            .then(res17=>res17.json())
            .then((res18)=>{
           fetch("https://halal-dropship-main-back.vercel.app/main-signup-user-check",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
              CNIC: CINC,
              CNIC1: res8.url,
              CNIC2 : res18.url,
              SellingStrategy  ,
              BankName  ,
              AccountOwnerName  ,
              AccountNumberIBAN  ,
              user  ,
              email  ,
              pass  ,
              phone             })
          })
          .then((res)=>res.json())
      .then((res2)  =>{
          console.log(res2)
          if (res2 !== null && !res2.Error  ){
            localStorage.setItem("set user value" , JSON.stringify({
              user  ,
                email  ,
                pass  ,
                phone ,
                CNIC: CINC,
                CNIC1: res8.url,
                CNIC2 : res18.url,
                SellingStrategy  ,
                BankName  ,
                AccountOwnerName  ,
                AccountNumberIBAN  ,
            }))
            setgy(false)
            setTimeout(()=>{
              onSignInSubmit()
            },2000)
            // props.history.push("/user/login")
          }
          else{
            swal("Phone Number Already Exists"  )

          }
          })
        })
        })
        }
























    const configureCaptcha = () => {
      const auth = getAuth();
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisble',
        'callback': (response) => {
          onSignInSubmit()
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        'expired-callback': () => {
          onSignInSubmit()

          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      }, auth);

      // window.recaptchaVerifier = new RecaptchaVerifier(
      //   "sign-in-button",
      //   {
      //     size: "invisible",
      //     callback: (response) => {
      //       // reCAPTCHA solved, allow signInWithPhoneNumber.
      //       onSignInSubmit();
      //     },
      //   },
      //   auth
      // );
    };
    const onSignInSubmit = () => {
      // e.preventDefault();

configureCaptcha();
      const phoneNumber =  phone;
      console.log(phoneNumber);
      const appVerifier = window.recaptchaVerifier;
      const auth = getAuth();
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          console.log("OTP has been sent");
          swal("Check your Otp")

          // localStorage.removeItem("set user value")
          // setgy(true)
          // ...
        })
        .catch((error) => {
          // Error; SMS not sent
          // ...
          console.log("SMS not sent",error);
          // grecaptcha.reset(window.recaptchaWidgetId);

          // window.recaptchaVerifier.render().then(function(widgetId) {
          //   grecaptcha.reset(widgetId);
          // })
          // swal("Connection Problem Reload This Page")
          // window.href = "https://studentlocal.netlify.app/user/signup"
        });
      // }
      // else{
      //   window.location.reload();
      //   localStorage.setItem("set user value" , JSON.stringify({
      //     user  ,
      //       email  ,
      //       pass  ,
      //       phone 
      //   }))
      // }
      
    };
    
const postData1 = (e) =>{
    e.preventDefault()
    // CONSOLE.LOG("EFSEF")
    
    
         window.confirmationResult
         .confirm(code)
         .then((result) => {
           // User signed in successfully.
           const user1 = result.user;
           console.log(JSON.stringify(user1));
          //  alert("User is verified");
          console.log(user  ,
            email  ,
            pass  ,
            phone )
            const data1 = new FormData()
           data1.append("file", CINC1)
           data1.append("upload_preset", "commerce")
           data1.append("cloud_name", "freedeveloperss123")
           fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
               method : "POST",
               body : data1 , 
           })
           .then(res7=>res7.json())
           .then((res8)=>{
            const data2 = new FormData()
            data2.append("file", CINC2)
            data2.append("upload_preset", "commerce")
            data2.append("cloud_name", "freedeveloperss123")
            fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                method : "POST",
                body : data2 , 
            })
            .then(res17=>res17.json())
            .then((res18)=>{
            fetch("https://halal-dropship-main-back.vercel.app/main-signup-user",{
                    method: 'POST' , 
                    headers :  {
                      "Content-Type" : "application/json" , 
                    } , 
                    body : JSON.stringify({
                      user  ,
                      email  ,
                      pass  ,
                      phone ,
                      CNIC: CINC,
                      CNIC1: res8.url,
                      CNIC2 : res18.url,
                      SellingStrategy  ,
                      BankName  ,
                      AccountOwnerName  ,
                      AccountNumberIBAN  ,
                    })
                  })
                  .then((res)=>res.json())
                  .then((res2)  =>{
                      console.log(res2)
                      if (res2 !== null && !res2.Error  ){
                        swal("SucessFully Create your Account"  )
                        // localStorage.setItem("User" , JSON.stringify("Yes") )
                         localStorage.removeItem("set user value")

                        props.history.push("/user/login")
                      }
                      else if (res2.Error){
                        swal(res2.Error )
                      }
                      else{
                        swal("Email & Password are Incorrect Plz Try Again !"  )
                      }
                    // console.log(res2)
                  })
                  .catch((err)=>console.log(err))

           // ...
         })
         .catch((error) => {
          swal("Enter Correct Otp"  )


           // User couldn't sign in (bad verification code?)
           // ...
         });
         });
         });
      
    }

















  return (
    <>
    { gy ?
       <div className="container-fuild rgjrgou" style={{width : "30%" , margin : "140px auto",padding: "40px 41px",boxShadow: "0px 2px 6px black"}}>
           
                <div id="sign-in-button"></div>

                <div style={{width: "90%",margin: "auto"}}>
                <center>

                    <h4 style={{color : "rgb(61, 61, 61)",fontWeight :"bold"}}>Register
</h4>
                    <h6 style={{color : "rgb(122, 122, 122)"}}>Already have an Account?  <span style={{textDecoration: "underline",color : "grey"}}> <Link to="/user/login" style={{color : "grey"}}> Login Now</Link></span></h6>
               </center>
                </div>
                <br />
                {/* </center> */}

                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>User name</label>
                    <input type="text"  value={user} onChange={(e)=>setuser(e.target.value)}  className="form-control" placeholder="Enter Username" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Email address</label>
                    <input type="email"  value={email} onChange={(e)=>setemail(e.target.value)}  className="form-control" placeholder="Enter email" />
                </div>
                <br />
                {/* <div id="recaptcha-container"></div> */}
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Phone </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="text"  value={phone} onChange={(e)=>setphone(e.target.value)}  className="form-control" placeholder="Enter phone" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Password</label>
                    <input type="password"   value={pass} onChange={(e)=>setpass(e.target.value)}  className="form-control" placeholder="Enter password" />
                </div>
            <br/>
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Explain Your Selling Strategy
 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <textarea type="text"  cols={20} rows={4} value={SellingStrategy} onChange={(e)=>setSellingStrategy(e.target.value)}  className="form-control" placeholder="Enter Please add links to site/profile which you are using to sell goods." ></textarea>
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>C.N.I.C.#

 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="number"  value={CINC} onChange={(e)=>setCINC(e.target.value)}  className="form-control" placeholder="Enter CINC" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>CNIC Front Image <img src={cnic1} alt="" style={{height:"50px",width  :"90px"}}/>

 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="file" onChange={(e)=>setCINC1(e.target.files[0])}  className="form-control" placeholder="Enter CINC" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>CNIC Back Image  <img src={cnic2} alt="" style={{height:"50px",width  :"90px"}}/>


 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="file" onChange={(e)=>setCINC2(e.target.files[0])}  className="form-control" placeholder="Enter CINC" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Bank Name


 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="text"  value={BankName} onChange={(e)=>setBankName(e.target.value)}  className="form-control" placeholder="Enter Bank Name
" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Account Owner Name


 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="text"  value={AccountOwnerName} onChange={(e)=>setAccountOwnerName(e.target.value)}  className="form-control" placeholder="Enter Account Owner Name
" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{fontWeight :"bold" , color : "black",margin:"0px"}}>Account Number or IBAN

 </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="number"  value={AccountNumberIBAN} onChange={(e)=>setAccountNumberIBAN(e.target.value)}  className="form-control" placeholder="Enter Account Number or IBAN" />
                </div>
                <br />
                {/* <span style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}} id={"changeIcon111"} ></span>   <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href={"#collapsebankssa1"} role="button" aria-expanded="false" aria-controls={"collapsebankssa1"} style={{color : "black"}}>  */}
                <input type="checkbox" name="vehicle3" value={chack1} onChange={()=>setchack1(true)} style={{marginTop: "-10px",marginRight: "10px"}}/> 
     
                                              I have read and agree to the website terms and conditions *
                                              {/* </a></h3> */}
                <br />
                    <button  onClick={()=>postData()} className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "grey" , border : "1px solid grey",fontWeight : "bold"}}>Sign Up</button>
            
               
        </div>



:



<div className="container-fuild rgjrgou" style={{width : "30%" , margin : "50px auto",padding: "40px 41px",boxShadow: "0px 2px 6px red"}}>
            <form  onSubmit={(e)=>postData1(e)}>
                {/* <center> */}
                <div id="sign-in-button"></div>

                <div style={{width: "90%",margin: "auto"}}>
                    <h4 style={{color : "rgb(61, 61, 61)"}}>Create your Account</h4>
                    <h6 style={{color : "rgb(122, 122, 122)"}}>Already have an Account?  <span style={{textDecoration: "underline",color : "rgb(225, 80, 94)"}}> <Link to="/user/login" style={{color : "rgb(225, 80, 94)"}}> Login Now</Link></span></h6>
                </div>
                <br />
                {/* </center> */}
                <div id="recaptcha-container"></div>
               
                  
                  <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Code</label>
                    <input type="number"  value={code} onChange={(e)=>setcode(e.target.value)}  className="form-control" placeholder="Enter 6 digit Code" />
                </div>
                <br />
              
                {/* <span onClick={()=>onSignInSubmit()} className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(227, 77, 92)" , border : "1px solid rgb(227, 77, 92)"}}>Submit</span> */}

                <button type="submit" className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(227, 77, 92)" , border : "1px solid rgb(227, 77, 92)"}}>Submit</button>

                {/* {gy ?
                    : 
                    <span  className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(227, 77, 92)" , border : "1px solid rgb(227, 77, 92)"}}>Sign Up</span>
                } */}
                {/* <p className="forgot-password text-right">
                    Forgot <Link to="/forget-pass-admin">password?</Link>
                </p> */}
                {/* <p className=" text-center">
                    <Link to="/login">Already Have an Account </Link>
                </p> */}
            </form>
        </div>
    }
    </>
  )
}
export default Signup